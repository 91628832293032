import React from "react";
import ToolTip from "../tooltip/ToolTip";

const Coe = (props) => {
  const { coeImpact } = props;

  let sign = null;
  if (coeImpact > 0) {
    sign = <span className="coe-value-sign">&#43;</span>;
  }

  return (
    <div className="coe">
      <h4 className="coe-header">ANNUAL COST OF EMPLOYMENT</h4>
      <div className="info">
        <p className="text-coe">
          Indication of changed annual cost of employment as a result of
          position adjustment(s).
        </p>
        <ToolTip
          message="Please note: 
          Total cost of employment shown is based on standard pay structure of country selected. Actual amount may be (significantly) different due to specific pay structures for the location, Business or Function. Furthermore, actual pay of incumbents depends on position in the pay scale range."
          side="left"
        >
          <span className="icon-ball-info">i</span>
        </ToolTip>
      </div>
      <h2 className="coe-text">
        {sign}{" "}
        {new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        }).format(coeImpact)}
      </h2>
    </div>
  );
};

export default Coe;
