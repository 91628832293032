import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const managerSlice = createSlice({
  name: "lineManager",
  initialState: {
    isLoading: false,
    isAuthorized: false,
    error: null,
    teamDetails: null,
    changes: [],
    managementLevels: null,
    noOfChanges: 0,
    coeImpactOfPosition: 0,
    compressionOfTeamToBe: null,
    coeOfSelectedPosition: 0,
    files: [],
    isSubmitted: false,
    showLink: false,
    sameJGReportingsInTeam: 0,
    jobDescriptionText: null,
    jobDescriptionFile: null,
  },
  reducers: {
    onFetchStarts: (state) => {
      state.isLoading = true;
      state.error = null;
      state.isAuthorized = false;
    },
    onFetchSuccess: (state, action) => {
      state.teamDetails = action.payload;
      state.managementLevels = action.payload.map((value) => {
        const values = {};
        values.id = value.id;
        values.managementLevel = value.ManagementLevel;
        values.managementLevelForSameJg = value.managementLevelForSameJg;
        return values;
      });
      state.isLoading = false;
      state.isAuthorized = true;
    },
    onFetchFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    onPositionChange: (state) => {
      state.noOfChanges = state.noOfChanges + 1;
    },
    onUndoChange: (state) => {
      state.noOfChanges = state.noOfChanges - 1;
    },

    onRemovalPosition: (state, action) => {
      state.teamDetails = state.teamDetails.map((object) =>
        object.id === action.payload.id
          ? {
              ...object,
              isRemoved: true,
              comments: action.payload.reasonForAction,
            }
          : object
      );
      state.managementLevels = state.managementLevels.filter(
        (value) => value.id !== action.payload.id
      );
      state.coeImpactOfPosition =
        state.coeImpactOfPosition - action.payload.coeOfPosition;
      state.changes = state.changes.concat(
        state.teamDetails.find((value) => value.id === action.payload.id)
      );
    },

    onUndoRemoval: (state, action) => {
      state.teamDetails = state.teamDetails.map((object) =>
        object.id === action.payload.id
          ? {
              ...object,
              isRemoved: false,
              comments: null,
            }
          : object
      );
      state.managementLevels = state.managementLevels.concat({
        id: action.payload.id,
        managementLevel: action.payload.managementLevel,
      });
      state.coeImpactOfPosition =
        state.coeImpactOfPosition + action.payload.coeOfPosition;
      state.changes = state.changes.filter(
        (value) => value.id !== action.payload.id
      );
    },

    onAddNewPosition: (state, action) => {
      state.teamDetails = state.teamDetails.concat(action.payload);
      state.managementLevels = state.managementLevels.concat({
        id: action.payload.id,
        managementLevel: Number(action.payload.ManagementLevel),
      });
      state.noOfChanges = state.noOfChanges + 1;
      state.coeImpactOfPosition =
        state.coeImpactOfPosition + action.payload.coeOfPosition;
      state.changes = state.changes.concat(action.payload);
    },

    onChangePosition: (state, action) => {
      state.teamDetails = state.teamDetails.concat(action.payload);
      state.teamDetails = state.teamDetails.map((object) =>
        object.id === Number(action.payload.positionId)
          ? { ...object, isActionNotAllowed: true }
          : object
      );
      state.managementLevels = state.managementLevels.map((value) =>
        value.id === Number(action.payload.positionId)
          ? {
              ...value,
              managementLevel: Number(action.payload.ManagementLevel),
            }
          : value
      );
      state.noOfChanges = state.noOfChanges + 1;
      state.coeImpactOfPosition =
        state.coeImpactOfPosition + action.payload.coeOfPosition;
      state.changes = state.changes.concat(action.payload);
    },

    removePosition: (state, action) => {
      state.teamDetails = state.teamDetails.filter(
        (value) => value.positionId !== action.payload.id
      );
      state.teamDetails = state.teamDetails.map((object) =>
        object.positionId === Number(action.payload.id)
          ? { ...object, isActionNotAllowed: false }
          : object
      );
      state.managementLevels = state.managementLevels.filter(
        (value) => value.id !== action.payload.id
      );
      state.noOfChanges = state.noOfChanges - 1;
      state.coeImpactOfPosition =
        state.coeImpactOfPosition - action.payload.coeOfPosition;
      state.changes = state.changes.filter(
        (value) => value.positionId !== action.payload.id
      );
    },

    onRemoveChangedPosition: (state, action) => {
      state.teamDetails = state.teamDetails.filter(
        (value) => value.id !== action.payload.id
      );
      state.teamDetails = state.teamDetails.map((object) =>
        object.positionId === Number(action.payload.positionId)
          ? { ...object, isActionNotAllowed: false }
          : object
      );
      state.managementLevels = state.managementLevels.map((value) =>
        value.id === Number(action.payload.positionId)
          ? {
              ...value,
              managementLevel: Number(action.payload.managementLevel),
            }
          : value
      );
      state.noOfChanges = state.noOfChanges - 1;
      state.coeImpactOfPosition =
        state.coeImpactOfPosition - action.payload.coe;
      state.changes = state.changes.filter(
        (value) => value.positionId !== action.payload.positionId
      );
    },

    coeImpactOnRemoval: (state, action) => {
      state.coeImpactOfPosition = state.coeImpactOfPosition - action.payload;
    },

    coeImpactOnUndoRemoval: (state, action) => {
      state.coeImpactOfPosition = state.coeImpactOfPosition + action.payload;
    },

    resetCostAndChanges: (state) => {
      state.coeImpactOfPosition = 0;
      state.noOfChanges = 0;
      state.changes = [];
      state.isSubmitted = false;
    },

    onUploadFile: (state, action) => {
      state.files = state.files.concat(action.payload);
    },

    onSubmission: (state) => {
      state.isSubmitted = true;
    },

    onShowLink: (state) => {
      state.showLink = true;
    },

    onHideLink: (state) => {
      state.showLink = false;
    },

    onUploadJdFile: (state, action) => {
      state.jobDescriptionFile = action.payload;
    },

    onUpdatedJdText: (state, action) => {
      state.jobDescriptionText = action.payload;
    },
  },
});

export const {
  onFetchStarts,
  onFetchSuccess,
  onFetchFailure,
  onPositionChange,
  onUndoChange,
  onRemovalPosition,
  onUndoRemoval,
  onAddNewPosition,
  onChangePosition,
  removePosition,
  onRemoveChangedPosition,
  coeImpactOnRemoval,
  coeImpactOnUndoRemoval,
  resetCostAndChanges,
  onUploadFile,
  onSubmission,
  onShowLink,
  onHideLink,
  onUpdatedJdText,
  onUploadJdFile,
} = managerSlice.actions;

export const fetchDetails = (positionId) => async (dispatch) => {
  dispatch(onFetchStarts());
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/positions`,
      {
        positionId,
      }
    );
    const updatedData = response.data.map((value) => {
      const object = {};
      object.id = value.Position_ID;
      object.isManager = value.IsManager_Flag;
      object.jeId = value.JE_Id;
      object.positionUnfilled = value.Position_IsUnfilled_Flag;
      object.positionId = value.Position_ID;
      object.positionTitle = value.Position_Title;
      object.managementLevelForSameJg =
        value.Management_Level_For_COE_Same_JG_Flagging;
      object.ManagementLevel = value.Management_Level_For_Compression_Calc;
      object.editAndDeleteAllowed = value.Position_All_Action_Allow_Flag;
      object.totalNoOfSameJg = value.Total_Same_JG_Reporting_In_Team;
      object.spanOfControl = value.Span_Of_Control;
      object.compression = value.Compression_For_Team;
      object.maxNoOfLayers = value.Org_Level_Of_Direct_Reports;
      object.jobFamilyGroup = value.Job_Family_Group;
      object.jobFamily = value.Job_Family;
      object.jobProfile = value.Job_Profile_With_Job_ID;
      object.location = value.Country_Name_For_COE;
      object.coeOfPosition = value.Indicative_COE_For_Position;
      object.differenceInManagementLevel = value.JG_Step_Diff_From_Supervisor;
      object.isRemoved = false;
      object.isChanged = false;
      object.isDuplicated = false;
      object.isActionNotAllowed = false;
      object.showBlue = false;
      object.coeDelta = null;
      object.comments = null;
      object.attachment = null;
      object.jdUrl = null;
      object.undo = false;
      object.isNewPosition = false;
      return object;
    });
    dispatch(onFetchSuccess(updatedData));
  } catch (error) {
    dispatch(onFetchFailure({ message: "Something went wrong!!" }));
  }
};

export const selectLineManager = (state) => ({
  teamDetails: state.lineManager.teamDetails,
  isLoading: state.lineManager.isLoading,
  error: state.lineManager.error,
  spanOfControlOfTeam: state.lineManager.spanOfControlOfTeam,
  compressionOfTeam: state.lineManager.compressionOfTeam,
  maxNoOfLayersOfTeam: state.lineManager.maxNoOfLayersOfTeam,
  sameJGReportingsInTeam: state.lineManager.sameJGReportingsInTeam,
  changes: state.lineManager.changes,
  noOfChanges: state.lineManager.noOfChanges,
  coeImpact: state.lineManager.coeImpactOfPosition,
  managementLevels: state.lineManager.managementLevels,
  compressionOfTeamToBe: state.lineManager.compressionOfTeamToBe,
  isSubmitted: state.lineManager.isSubmitted,
  showLink: state.lineManager.showLink,
  isAuthorized: state.lineManager.isAuthorized,
  jobDescriptionText: state.lineManager.jobDescriptionText,
  jobDescriptionFile: state.lineManager.jobDescriptionFile,
});

export default managerSlice.reducer;
