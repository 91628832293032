import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const userSlice = createSlice({
  name: "auth",
  initialState: {
    name: null,
    isLoggedIn: false,
    isLoading: false,
    isSSOAllowed: false,
    error: null,
    adminParameters: null,
    isLoggedOut: false,
  },
  reducers: {
    loading: (state) => {
      state.isLoading = true;
    },
    login: (state, action) => {
      state.name = action.payload.Worker_Full_Name;
      state.positionId = action.payload.Position_ID;
      state.positionTitle = action.payload.Position_Title;
      state.lmEC = action.payload.EC;
      state.lmEvpGrouping = action.payload.EVP_Grouping;
      state.lmOrgUnit5 = action.payload.Org_Unit_5;
      state.email = action.payload.Email_Address_SSO;
      state.numberOfDirectReports = action.payload.Org_Level_Of_Position;
      state.managementLevel = parseInt(
        action.payload.Management_Level_For_Compression_Calc
      );
      state.managementLevelForSameJg =
        action.payload.Management_Level_For_COE_Same_JG_Flagging;
      state.superVisorName = action.payload.Supervisor_Full_Name;
      state.supervisorEmail = action.payload.Supervisor_Email_Address;
      state.numberOfSameJobGroupReporting =
        action.payload.Total_Same_JG_Reporting_In_Team;
      state.spanOfControl = parseInt(action.payload.Span_Of_Control);
      state.compression = parseFloat(action.payload.Compression_For_Team);
      state.maxNoOfLayers = parseInt(
        action.payload.Org_Level_Of_Direct_Reports
      );
      state.spanOfControlToBe = parseInt(action.payload.Span_Of_Control);
      state.compressionToBe = parseFloat(action.payload.Compression_For_Team);
      state.maxNoOfLayersToBe = parseInt(
        action.payload.Org_Level_Of_Direct_Reports
      );
      state.location = action.payload.Country_Name_For_COE;
      state.familyGroup = action.payload.Job_Family_Group;
      state.jobFamily = action.payload.Job_Family;
      state.jobProfile = action.payload.Job_Profile_With_Job_ID;
      state.coeOfTeam = parseInt(action.payload.Indicative_COE_For_Team);
      state.isSSOAllowed = action.payload.SSO_Allowed_Flag;
      state.isLoggedIn = true;
      state.isLoggedOut = false;
      state.isLoading = false;
    },
    addAdminParameters: (state, action) => {
      state.adminParameters = action.payload;
    },
    loginFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    logout: (state) => {
      state.isAuthorized = false;
      state.isLoggedIn = false;
      state.isLoggedOut = true;
    },
    onRemove: (state) => {
      state.spanOfControlToBe = state.spanOfControlToBe - 1;
    },
    onUndo: (state) => {
      state.spanOfControlToBe = state.spanOfControlToBe + 1;
    },
    onAddNew: (state) => {
      state.spanOfControlToBe = state.spanOfControlToBe + 1;
    },
    resetOsh: (state) => {
      state.spanOfControlToBe = state.spanOfControl;
      state.compressionToBe = state.compression;
      state.maxNoOfLayersToBe = state.maxNoOfLayers;
    },
  },
});

export const {
  loading,
  login,
  loginFailed,
  logout,
  onRemove,
  onUndo,
  onAddNew,
  resetOsh,
  addAdminParameters,
} = userSlice.actions;

export const loginAsync = (email) => async (dispatch) => {
  dispatch(loading());
  try {
    const response = await axios.post(`${process.env.REACT_APP_API_URL}/auth`, {
      user: email,
    });
    const adminParameters = await axios.get(
      `${process.env.REACT_APP_API_URL}/get-control-set`
    );
    const responses = await Promise.all([response, adminParameters]);
    dispatch(login(responses[0].data));
    dispatch(addAdminParameters(responses[1].data));
  } catch (error) {
    dispatch(loginFailed({ message: "user is not authorized" }));
  }
};

export const selectAuth = (state) => ({
  name: state.auth.name,
  email: state.auth.email,
  superVisorName: state.auth.superVisorName,
  supervisorEmail: state.auth.supervisorEmail,
  lmEC: state.auth.lmEC,
  lmEvpGrouping: state.auth.lmEvpGrouping,
  lmOrgUnit5: state.auth.lmOrgUnit5,
  positionId: state.auth.positionId,
  jobProfile: state.auth.jobProfile,
  managementLevelOfLineManger: state.auth.managementLevel,
  managementLevelOfLineManagerForSameJg: state.auth.managementLevelForSameJg,
  maxNoOfLayers: state.auth.maxNoOfLayers,
  location: state.auth.location,
  positionTitle: state.auth.positionTitle,
  spanOfControlOfTeam: state.auth.spanOfControl,
  compressionOfTeam: state.auth.compression,
  maxNoOfLayersOfTeam: state.auth.maxNoOfLayers,
  coeOfTeam: state.auth.coeOfTeam,
  spanOfControlOfTeamToBe: state.auth.spanOfControlToBe,
  compressionOfTeamToBe: state.auth.compressionToBe,
  maxNoOfLayersOfTeamToBe: state.auth.maxNoOfLayersToBe,
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.auth.isLoading,
  isSSOAllowed: state.auth.isSSOAllowed,
  sameJGReportingsInTeamOfLineManager: state.auth.numberOfSameJobGroupReporting,
  error: state.auth.error,
  adminParameters: state.auth.adminParameters,
  isLoggedOut: state.auth.isLoggedOut,
});

export default userSlice.reducer;
