import React from "react";
import { Link } from "react-router-dom";
import HrIcon from "../../components/icons/hr/HrIcon";
import WelcomePageLink from "../../components/links/welcome-page-link/WelcomePageLink";
import {
  hrAdviceRepresentative,
  evaUserGuide,
  evaUserGuideShort,
  systemSupportLink,
  evaNavigationVideo,
  welcometoEVAVideo

} from "../../utils/utils";
import VideoLinkIcon from "../../components/icons/link/videoLinkIcon"
import DocumentLinkIcon from '../../components/icons/link/documentLinkIcon';

const WelcomePageLinks = (props) => {
  return (
    <React.Fragment>
      <section className="section-contacts">
        <div className="title-bar">
          <span className="icon-box">
            <HrIcon />
          </span>
          <h1 className="title-links mar-left-util">FURTHER INFORMATION</h1>
        </div>
        <div className="links-list">
          <Link
            to={{
              pathname: welcometoEVAVideo,
            }}
            target="_blank"
          >
            <div className="link-to">
              <span className="icon-container">
                <VideoLinkIcon />
              </span>
             <span className="link-text">Welcome to EVA Video</span>
            </div>
          </Link>
          <Link
            to={{
              pathname: evaNavigationVideo,
            }}
            target="_blank"
          >
            <div className="link-to">
              <span className="icon-container">
                <VideoLinkIcon />
              </span>
             <span className="link-text">EVA Navigation Video</span>
            </div>
          </Link>
          <Link
            to={{
              pathname: evaUserGuideShort,
            }}
            target="_blank"
          >
            <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text">EVA User Guide (Short)</span>
            </div>
          </Link>
          <Link
            to={{
              pathname: evaUserGuide,
            }}
            target="_blank"
          >
            <div className="link-to">
              <span className="icon-container">
                <DocumentLinkIcon />
              </span>
             <span className="link-text">EVA User Guide (Full)</span>
            </div>
          </Link>

          <Link
            to={{
              pathname: hrAdviceRepresentative,
            }}
            target="_blank"
          >
            <WelcomePageLink>HR Advice Representative</WelcomePageLink>
          </Link>

          <Link
            to={{
              pathname: systemSupportLink,
            }}
            target="_blank"
          >
            <WelcomePageLink>HR Systems support</WelcomePageLink>
          </Link>
        </div>
      </section>
    </React.Fragment>
  );
};

export default WelcomePageLinks;
