import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectLineManager } from "../../app/features/lineManagerSlice";
import Spinner from "../spinner/Spinner";

const Auth = (props) => {
  const { isAuthorized } = useSelector(selectLineManager);
  useEffect(() => {
    if (window.localStorage.getItem("fromLogout") !== "true" && !isAuthorized) {
      props.login();
    } else {
      window.localStorage.removeItem("fromLogout");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};

export default Auth;
