import NewPositionButton from "../../components/new-position-button/NewPositionButton";
import IndividualContributor from "../individual-contributor/IndividualContributor";
import ManagerPosition from "../manager-position/ManagerPosition";

const Teams = (props) => {
  const { teamDetails } = props;

  if (!teamDetails || teamDetails.length === 0) {
    return <h1>No teams found</h1>;
  }

  const sortedTeamStructure = [...teamDetails].sort(
    (a, b) => Number(b.isManager) - Number(a.isManager)
  );

  const teamStructure = sortedTeamStructure.map((position, index) => {
    if (position.isManager) {
      return (
        <ManagerPosition
          isManager={position.isManager}
          isChanged={position.isChanged}
          isNewPosition={position.isNewPosition}
          isDuplicated={position.isDuplicated}
          key={position.id}
          id={position.id}
          managementLevel={position.ManagementLevel}
          positionTitle={position.positionTitle}
          positionId={position.positionId}
          jobProfile={position.jobProfile}
          location={position.location}
          isUnfilled={position.positionUnfilled}
          isEditAndDeleteAllowed={position.editAndDeleteAllowed}
          spanOfControl={position.spanOfControl}
          compression={position.compression}
          sameJobReportings={position.totalNoOfSameJg}
          maxNoOfLayers={position.maxNoOfLayers}
          coeOfPosition={position.coeOfPosition}
          isActionNotAllowed={position.isActionNotAllowed}
          showBlue={position.showBlue}
        />
      );
    } else {
      return (
        <IndividualContributor
          isManager={position.isManager}
          key={position.id}
          id={position.id}
          managementLevel={position.ManagementLevel}
          positionTitle={position.positionTitle}
          positionId={position.positionId}
          jobProfile={position.jobProfile}
          location={position.location}
          isUnfilled={position.positionUnfilled}
          isEditAndDeleteAllowed={position.editAndDeleteAllowed}
          isRemoved={position.isRemoved}
          isNewPosition={position.isNewPosition}
          isEdited={position.isChanged}
          isDuplicated={position.isDuplicated}
          coeOfPosition={position.coeOfPosition}
          isActionNotAllowed={position.isActionNotAllowed}
          showBlue={position.showBlue}
        />
      );
    }
  });

  return (
    <div className="team-positions">
      {teamStructure}
      <NewPositionButton />
    </div>
  );
};

export default Teams;
