import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Plus from "../icons/plus/Plus";

import { selectLineManager } from "../../app/features/lineManagerSlice";
import Swal from "sweetalert2";
import warning from "../../assets/warningIcon.png";
const NewPositionButton = (props) => {
  const history = useHistory();
  const { noOfChanges } = useSelector(selectLineManager);

  const handleOnClick = () => {
    if (noOfChanges < 3) {
      history.push(`/home/new`);
    } else {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl: warning,
        imageHeight: 75,
        imageWidth: 75,
        imageAlt : "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }
  };

  return (
    <div className="new-position-button" onClick={handleOnClick}>
      <Plus />
      Add New Position
    </div>
  );
};

export default NewPositionButton;
