import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import Button from "../../components/button/Button";
import Location from "../../components/icons/location/Location";
import ToolTip from "../tooltip/ToolTip";
import { onRemove, onUndo, selectAuth } from "../../app/features/authSlice";
import {
  selectLineManager,
  onPositionChange,
  onUndoChange,
  onRemovalPosition,
  onUndoRemoval,
  removePosition,
  onRemoveChangedPosition,
} from "../../app/features/lineManagerSlice";
import success from "../../assets/success.png";
import info from "../../assets/infoIcon.png";
import warning from "../../assets/warningIcon.png";

const IndividualContributor = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    positionTitle,
    positionId,
    jobProfile,
    location,
    isUnfilled,
    isEditAndDeleteAllowed,
    isNewPosition,
    isEdited,
    isDuplicated,
    managementLevel,
    isRemoved,
    coeOfPosition,
    isActionNotAllowed,
  } = props;

  const { noOfChanges, teamDetails } = useSelector(selectLineManager);
  const { spanOfControlOfTeamToBe } = useSelector(selectAuth);

  const currentPosition = teamDetails.find(
    (value) => value.positionId === positionId
  );

  const getJobIdWithProfile = (jobProfile) => {
    if (!jobProfile) return [];
    var i = jobProfile.indexOf("-");
    if (i > 0) {
      return [jobProfile.substring(0, i), jobProfile.substring(i + 1)];
    } else return [jobProfile];
  };

  const onChangeHandler = () => {
    if (noOfChanges < 3) {
      history.push(`/home/change/${positionId}`);
    } else {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }
  };

  const onDuplicateHandler = () => {
    if (noOfChanges < 3) {
      const customButtonClasses = Swal.mixin({
        customClass: {
          confirmButton: "change-button confirm",
          cancelButton: "change-button cancel",
        },
        buttonsStyling: false,
      });
      customButtonClasses
        .fire({
          html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">When duplicating any position only change in location is allowed</p>`,
          imageUrl:info,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "info",
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (noOfChanges < 3) {
              history.push(`/home/duplicate/${positionId}`);
            } else {
              Swal.fire({
                html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
                imageUrl: warning,
                imageWidth: 75,
                imageHeight: 75,
                imageAlt: "warning",
                confirmButtonText: "Okay",
                buttonsStyling: false,
                customClass: {
                  confirmButton: "swal-button-manager-remove",
                },
              });
            }
          }
        });
    } else {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }
  };

  const onRemoveHandler = (id, managementLevel) => {
    if (spanOfControlOfTeamToBe === 1 && noOfChanges <= 3 && !isRemoved) {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">You are about to remove the last position from your team. If this is your intention, please reach out to HR Advice.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "BACK",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }

    if (
      noOfChanges >= 3 &&
      !isRemoved &&
      !isEdited &&
      !isNewPosition &&
      !isDuplicated
    ) {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    } else if (
      (isNewPosition || isDuplicated) &&
      spanOfControlOfTeamToBe !== 1
    ) {
      dispatch(removePosition({ id, coeOfPosition }));
      dispatch(onRemove());
    } else if (isEdited) {
      dispatch(
        onRemoveChangedPosition({
          positionId: id,
          id: props.id,
          managementLevel: currentPosition.ManagementLevel,
          coe: coeOfPosition,
        })
      );
    } else if (noOfChanges <= 3 && isRemoved) {
      dispatch(onUndo());
      dispatch(onUndoChange());
      dispatch(onUndoRemoval({ id, managementLevel, coeOfPosition }));
    } else if (
      (!isEdited || !isNewPosition || !isDuplicated) &&
      spanOfControlOfTeamToBe !== 1
    ) {
      const customButtonClasses = Swal.mixin({
        customClass: {
          confirmButton: "change-button confirm",
          cancelButton: "change-button cancel",
          input: "input_action",
          text: "pop-up-text",
        },
        buttonsStyling: false,
      });
      customButtonClasses
        .fire({
          html: `<h3>Are You Sure?</h3><p style="margin:5px 0;font-size:15px;text-align:center;font-weight:700;color:#dd1d21">You want to remove this position in EVA</p>`,
          imageUrl: warning,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, Remove",
          cancelButtonText: "Cancel",
          input: "textarea",
          inputPlaceholder: "Reason for Action",
          inputValidator: (value) => {
            if (!value) {
              return "Please provide a reason for action";
            } else if (value.trim().length < 10 || value.trim().length > 250) {
              return "Should have 10-250 characters";
            }
          },
        })
        .then((result) => {
          if (result.isConfirmed) {
            dispatch(onRemove());
            dispatch(onPositionChange());
            dispatch(
              onRemovalPosition({
                id,
                coeOfPosition,
                reasonForAction: result.value,
              })
            );
          }
        });
    }
  };

  let border = "";
  if (isRemoved) {
    border = "removed-position";
  } else if (isNewPosition) {
    border = "new-position";
  } else if (isDuplicated) {
    border = "duplicated-position";
  } else if (isEdited) {
    border = "edited-position";
  }

  let hidden = "";
  if (isDuplicated || isNewPosition) {
    hidden = "hidden";
  }

  let backgroundForManager = "individual";
  if (isEdited && props.showBlue) {
    backgroundForManager = "manager";
  }

  let blurred = "";
  if (isActionNotAllowed) {
    blurred = "card-blur";
  }

  return (
    <div className={`individual-contributor ${border} ${backgroundForManager}`}>
      {isUnfilled ? (
        <span
          className={`unfilled-tag ${
            isRemoved || isEdited ? "position-is-removed" : "position"
          } ${blurred}`}
        >
          Unfilled
        </span>
      ) : null}
      {isActionNotAllowed && (
        <h1 className="changed-position-tag">POSITION CHANGED</h1>
      )}
      <div className={`individual-contributor-details ${blurred}`}>
        <p className="ind-cont-text util-font-bold">{positionTitle}</p>

        <p className={`ind-cont-text ${hidden}`}>
          Position Number : {isNewPosition || isDuplicated ? null : positionId}
        </p>

        <p className="ind-cont-text">
          Job Profile : {getJobIdWithProfile(jobProfile)[0]} -
        </p>
        <p className="ind-cont-text font-align-center">
          {getJobIdWithProfile(jobProfile)[1]}
        </p>
        <div className="location-box">
          <Location />
          <p className="location-text">{location}</p>
        </div>
      </div>
      <div className="card-buttons-manager">
        {isNewPosition ||
        isDuplicated ||
        isEdited ? null : !isEditAndDeleteAllowed ? (
          <ToolTip
            message="This position is not within ML 3-5 range and can therefore only be removed"
            side="top"
          >
            <span>
              <Button
                disabled={
                  !isEditAndDeleteAllowed ||
                  isRemoved ||
                  isEdited ||
                  isActionNotAllowed
                }
                primary
                size="small"
                onClick={onChangeHandler}
              >
                CHANGE
              </Button>
            </span>
          </ToolTip>
        ) : (
          <Button
            disabled={
              !isEditAndDeleteAllowed ||
              isRemoved ||
              isEdited ||
              isActionNotAllowed
            }
            primary
            size="small"
            onClick={onChangeHandler}
          >
            CHANGE
          </Button>
        )}
        <Button
          disabled={isActionNotAllowed}
          danger
          size="small"
          onClick={() => onRemoveHandler(positionId, managementLevel)}
        >
          {isRemoved || isEdited || isNewPosition || isDuplicated
            ? "UNDO"
            : "REMOVE"}
        </Button>
        {isNewPosition ||
        isDuplicated ||
        isEdited ? null : isEditAndDeleteAllowed ? (
          <Button
            duplicate
            size="small"
            disabled={
              !isEditAndDeleteAllowed ||
              isRemoved ||
              isEdited ||
              isActionNotAllowed
            }
            onClick={onDuplicateHandler}
          >
            DUPLICATE
          </Button>
        ) : (
          <ToolTip
            message="This position is not within ML 3-5 range and can therefore only be removed"
            side="top-right"
          >
            <span>
              <Button
                duplicate
                size="small"
                disabled={
                  !isEditAndDeleteAllowed ||
                  isRemoved ||
                  isEdited ||
                  isActionNotAllowed
                }
                onClick={onDuplicateHandler}
              >
                DUPLICATE
              </Button>
            </span>
          </ToolTip>
        )}
      </div>
    </div>
  );
};

export default IndividualContributor;
