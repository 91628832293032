import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { selectAuth } from "../../app/features/authSlice";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import OutcomePageLink from "../../components/links/outpage-link/OutcomePageLink";
import JobDescriptionLink from "../../components/links/job-description-link/JobDescriptionLink";
import DocumentLinkIcon from "../../components/icons/link/documentLinkIcon";
import VideoLinkIcon from "../../components/icons/link/videoLinkIcon";
import LinkIcon from "../../components/icons/link/LinkIcon";

import {
  evaUserGuide,
  hrAdviceRepresentative,
  managementLevelCompression,
  organisationLayers,
  settingManagementLevelsAtShell,
  spanOfControlLink,
  systemSupportLink,
  managementLevelProbabilityAnalysis,
} from "../../utils/utils";

const OutcomeRight = (props) => {
  const {
    isSubmitted,
    id,
    jdLinks,
    sameJGReportingsTobe,
    compressionOfTeamToBe,
    sameJGReportingAsIs,
  } = props;
  const {
    spanOfControlOfTeam,
    compressionOfTeam,
    maxNoOfLayersOfTeam,
    spanOfControlOfTeamToBe,
    maxNoOfLayersOfTeamToBe,
    adminParameters,
  } = useSelector(selectAuth);
  const { coeImpact, changes } = useSelector(selectLineManager);

  const {
    compressionGREENGreaterAndEqualTo,
    compressionGREENLessAndEqualTo,
    compressionORANGEUPPERGreaterAndEqualTo,
    compressionORANGEUPPERLessAndEqualTo,
    compressionREDGreaterAndEqualTo,
    compressionREDLessAndEqualTo,
    compressionORANGELOWERGreaterAndEqualTo,
    compressionORANGELOWERLessAndEqualTo,
    layersGREENGreaterAndEqualTo,
    layersGREENLessAndEqualTo,
    layersORANGEGreaterAndEqualTo,
    layersORANGELessAndEqualTo,
    sameJobGroupReportingGREENGreaterAndEqualTo,
    spanOfControlGREENGreaterAndEqualTo,
    spanOfControlGREENLessAndEqualTo,
    spanOfControlORANGEGreaterAndEqualTo,
    spanOfControlORANGELessAndEqualTo,
    spanOfControlREDGreaterAndEqualTo,
    spanOfControlREDLessAndEqualTo,
  } = adminParameters;

  const date = () => {
    const pad2 = (n) => (n < 10 ? "0" : "") + n;
    return `${pad2(new Date().getMonth() + 1)}.${pad2(
      new Date().getDate()
    )}.${pad2(new Date().getFullYear())}`;
  };

  let colorCodeSpanOfControlAsIs = null;
  if (
    spanOfControlOfTeam <= spanOfControlREDLessAndEqualTo &&
    spanOfControlOfTeam >= spanOfControlREDGreaterAndEqualTo
  ) {
    colorCodeSpanOfControlAsIs = "red-outcome";
  } else if (
    spanOfControlOfTeam <= spanOfControlORANGELessAndEqualTo &&
    spanOfControlOfTeam >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanOfControlAsIs = "amber-outcome";
  } else if (
    spanOfControlOfTeam >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControlOfTeam <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanOfControlAsIs = "green-outcome";
  }

  let colorCodeSpanOfControlToBe = null;
  if (
    (spanOfControlOfTeamToBe <= spanOfControlREDLessAndEqualTo &&
      spanOfControlOfTeamToBe >= spanOfControlREDGreaterAndEqualTo) ||
    spanOfControlOfTeamToBe === 0
  ) {
    colorCodeSpanOfControlToBe = "red-outcome";
  } else if (
    spanOfControlOfTeamToBe <= spanOfControlORANGELessAndEqualTo &&
    spanOfControlOfTeamToBe >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanOfControlToBe = "amber-outcome";
  } else if (
    spanOfControlOfTeamToBe >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControlOfTeamToBe <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanOfControlToBe = "green-outcome";
  }

  let colorCodeCompressionAsIs = null;
  if (
    compressionOfTeam >= compressionREDGreaterAndEqualTo &&
    compressionOfTeam <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompressionAsIs = "red-outcome";
  } else if (
    (compressionOfTeam >= compressionORANGELOWERGreaterAndEqualTo &&
      compressionOfTeam <= compressionORANGELOWERLessAndEqualTo) ||
    (compressionOfTeam >= compressionORANGEUPPERGreaterAndEqualTo &&
      compressionOfTeam <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompressionAsIs = "amber-outcome";
  } else if (
    compressionOfTeam >= compressionGREENGreaterAndEqualTo &&
    compressionOfTeam <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompressionAsIs = "green-outcome";
  }

  let colorCodeCompressionToBe = null;
  if (
    compressionOfTeamToBe >= compressionREDGreaterAndEqualTo &&
    compressionOfTeamToBe <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompressionToBe = "red-outcome";
  } else if (
    (compressionOfTeamToBe >= compressionORANGELOWERGreaterAndEqualTo &&
      compressionOfTeamToBe <= compressionORANGELOWERLessAndEqualTo) ||
    (compressionOfTeamToBe >= compressionORANGEUPPERGreaterAndEqualTo &&
      compressionOfTeamToBe <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompressionToBe = "amber-outcome";
  } else if (
    compressionOfTeamToBe >= compressionGREENGreaterAndEqualTo &&
    compressionOfTeamToBe <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompressionToBe = "green-outcome";
  }

  let colorCodeMaxLayer = null;
  if (
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) >=
      layersGREENGreaterAndEqualTo &&
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) <=
      layersGREENLessAndEqualTo
  ) {
    colorCodeMaxLayer = "green-outcome";
  } else if (
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) >=
      layersORANGEGreaterAndEqualTo &&
    (maxNoOfLayersOfTeam || maxNoOfLayersOfTeamToBe) <=
      layersORANGELessAndEqualTo
  ) {
    colorCodeMaxLayer = "amber-outcome";
  }

  const colorCodeSameJgReportingAsIs = "red-outcome";
  let colorCodeSameJgReportingToBe = "red-outcome";
  if (sameJGReportingsTobe === sameJobGroupReportingGREENGreaterAndEqualTo) {
    colorCodeSameJgReportingToBe = "green-outcome";
  }

  const coe = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 0,
  }).format(coeImpact);

  let color = "";
  if (props.overallOutCome === "red") {
    color = "red";
  } else if (props.overallOutCome === "amber") {
    color = "amber";
  } else {
    color = "green";
  }

  let sign = null;
  if (coeImpact > 0) {
    sign = <span className="coe-value-sign">&#43;</span>;
  }

  let directionOfChangeSpanOfControl = null;
  if (spanOfControlOfTeamToBe > spanOfControlOfTeam) {
    directionOfChangeSpanOfControl = (
      <span className="outcome-arrow-up">&#9650;</span>
    );
  } else if (spanOfControlOfTeamToBe < spanOfControlOfTeam) {
    directionOfChangeSpanOfControl = (
      <span className="outcome-arrow-up">&#9660;</span>
    );
  }

  let directionOfChangeCompression = null;
  if (compressionOfTeamToBe > compressionOfTeam) {
    directionOfChangeCompression = (
      <span className="outcome-arrow-up">&#9650;</span>
    );
  } else if (compressionOfTeamToBe < compressionOfTeam) {
    directionOfChangeCompression = (
      <span className="outcome-arrow-up">&#9660;</span>
    );
  }

  let jdLinksContent = null;
  if (jdLinks.length !== 0 && isSubmitted) {
    jdLinksContent = jdLinks.map((jdLink) => (
      <li key={jdLink.positionId}>
        <Link
          to={{
            pathname: jdLink.jdUrl,
          }}
          target="_blank"
        >
          <JobDescriptionLink>
            {jdLink.isChanged
              ? `${jdLink.positionTitle.toUpperCase()} - ${jdLink.positionId}`
              : jdLink.positionTitle.toUpperCase()}
          </JobDescriptionLink>
        </Link>
      </li>
    ));
  }

  let overallEvaOutcomeText = "No EVA concerns";
  if (props.overallOutCome === "red") {
    overallEvaOutcomeText = "Major concerns";
  } else if (props.overallOutCome === "amber") {
    overallEvaOutcomeText = "Potential concerns";
  }

  const aiChanges = [];
  if (changes.length !== 0) {
    changes.map((v) => {
      if (!v.isDuplicated && !v.isRemoved) {
        aiChanges.push(v);
      }
      return aiChanges;
    });
  }

  return (
    <section className="right-side-outcome">
      <div className="outcome-right">
        <h3 className="outcome-title-right">OVERALL EVA OUTCOME</h3>
        <p className="outcome-block outcome-text-right">
          {isSubmitted && id ? (
            <span className="outcome-overall">
              Ref. NBR : <strong className="margin-util-3">{id} - </strong>
            </span>
          ) : null}
          <span
            className={`outcome-color ${color} ${!isSubmitted ? "center" : ""}`}
          >
            {isSubmitted && id ? props.overallOutCome : overallEvaOutcomeText}
          </span>
        </p>
        {isSubmitted ? (
          <p className="outcome-text outcome-text-right">
            Modelled in EVA as per : <strong>{date()}</strong>
          </p>
        ) : null}
      </div>

      <div className="osh-report-outcome">
        <h3 className="outcome-title-right">
          Team structure health (Direct Reports)
        </h3>
        <div className="outcome-osh-rows">
          <p className="outcome-osh-row column-1 background-yellow">
            DIMENSIONS
          </p>
          <p className="outcome-osh-row background-yellow">AS IS</p>
          <p className="outcome-osh-row background-yellow">TO BE</p>
        </div>
        <div className="outcome-osh-rows">
          <p className="outcome-osh-row column-1">SPAN OF CONTROL</p>
          <p className={`outcome-osh-row ${colorCodeSpanOfControlAsIs}`}>
            {spanOfControlOfTeam}
          </p>
          <p className={`outcome-osh-row ${colorCodeSpanOfControlToBe}`}>
            {spanOfControlOfTeamToBe} {directionOfChangeSpanOfControl}
          </p>
        </div>
        <div className="outcome-osh-rows">
          <p className="outcome-osh-row column-1">
            MANAGEMENT LEVEL COMPRESSION
          </p>
          <p className={`outcome-osh-row ${colorCodeCompressionAsIs}`}>
            {compressionOfTeam.toFixed(2)}
          </p>
          <p className={`outcome-osh-row ${colorCodeCompressionToBe}`}>
            {compressionOfTeamToBe} {directionOfChangeCompression}
          </p>
        </div>
        <div className="outcome-osh-rows">
          <p className="outcome-osh-row column-1">ORGANISATION LAYERS</p>
          <p className={`outcome-osh-row ${colorCodeMaxLayer}`}>
            {maxNoOfLayersOfTeam}
          </p>
          <p className={`outcome-osh-row ${colorCodeMaxLayer}`}>
            {maxNoOfLayersOfTeamToBe}
          </p>
        </div>
        {sameJGReportingAsIs !== null ? (
          <div className="outcome-osh-rows">
            <p className="outcome-osh-row column-1">SAME JG REPORTING</p>
            <p className={`outcome-osh-row ${colorCodeSameJgReportingAsIs}`}>
              {sameJGReportingAsIs}
            </p>
            <p className={`outcome-osh-row ${colorCodeSameJgReportingToBe}`}>
              {sameJGReportingsTobe}
            </p>
          </div>
        ) : null}
        <div className="outcome-osh-rows">
          <p className="outcome-osh-row column-1">
            Colours indicate alignment with (green), falling below (amber) or
            significantly below (red) Shell organisation design standards.{" "}
          </p>
        </div>

        {aiChanges.length === 0 ? null : (
          <div className="osh-report-outcome">
            <h3 className="outcome-title-right">
              EVA ANALYSIS OF MANAGEMENT LEVEL FOR NEW/CHANGED POSITIONS
            </h3>
            {changes.length !== 0 &&
              changes.map((value) => {
                let output = "Inconclusive";
                let background = "ai-parameter-amber";
                // POP UP 1
                if (
                  (value.confidence === "high" || value.confidence === "medium") &&
                  value.aiManagementLevel > value.initialManagementLevel
                ) {
                  //green
                  if (
                    value.ManagementLevel === value.aiManagementLevel &&
                    (value.confidence === "high" || value.confidence === "medium")
                  ) {
                    output = "Aligned";
                    background = "ai-parameter-blue";
                  }
                  //red
                  else if (
                    (value.confidence === "high" &&
                      value.aiManagementLevel !== value.ManagementLevel) ||
                    (value.confidence === "medium" &&
                      value.aiManagementLevel - value.ManagementLevel === 2 &&
                      value.aiManagementLevel - value.initialManagementLevel === 1)
                  ) {
                    output = "Misaligned";
                    background = "ai-parameter-pink";
                  }
                  //amber
                  else {
                    output = "Inconclusive";
                    background = "ai-parameter-amber";
                  }
                }
                // POP UP 3
                else if (
                  (value.confidence === "high" || value.confidence === "medium") &&
                  value.aiManagementLevel === value.initialManagementLevel
                ) {
                  //green
                  if (
                    value.ManagementLevel === value.aiManagementLevel &&
                    (value.confidence === "high" || value.confidence === "medium")
                  ) {
                    output = "Aligned";
                    background = "ai-parameter-blue";
                  }
                  //red
                  else if (
                    value.confidence === "high" &&
                    value.aiManagementLevel !== value.ManagementLevel
                  ) {
                    output = "Misaligned";
                    background = "ai-parameter-pink";
                  }
                  //amber
                  else if (
                    value.confidence === "medium" &&
                    value.aiManagementLevel !== value.ManagementLevel
                  ) {
                    output = "Inconclusive";
                    background = "ai-parameter-amber";
                  }
                }
                // POP UP 4
                else if (
                  (value.confidence === "high") &&
                  value.aiManagementLevel < value.initialManagementLevel
                ) {
                  if (
                    //green
                    value.confidence === "high" &&
                    value.ManagementLevel === value.aiManagementLevel
                  ) {
                    output = "Aligned";
                    background = "ai-parameter-blue";
                  }
                  //amber
                  // else if (
                  //   value.confidence === "medium" &&
                  //   value.aiManagementLevel === value.ManagementLevel
                  // ) {
                  //   output = "Inconclusive";
                  //   background = "ai-parameter-amber";
                  // }
                  //amber
                  else if (
                    (value.confidence === "high") &&
                    value.aiManagementLevel !== value.ManagementLevel
                  ) {
                    output = "Inconclusive";
                    background = "ai-parameter-amber";
                  }
                }
                //POP UP 2
                else if ((value.confidence === "low") || 
                ((value.confidence === "medium") && 
                (value.aiManagementLevel < value.initialManagementLevel))){
    
                  output = "Inconclusive";
                  background = "ai-parameter-amber";
                }
                if (!value.isDuplicated && !value.isRemoved) {
                  return (
                    <div className="ai-header-row-outcome" key={value.id}>
                    <p className="ai-row-outcome ai-header-parameter-outcome">
                      {value.positionTitle}
                    </p>
                    <h4 className={`ai-row-outcome ai-parameter-outcome ${background}`}>
                      {output}
                    </h4>
                  </div>
                  );
                }
              })}
          </div>
        )}

        <div className="outcome-coe">
          <h3 className="outcome-title-right">Annual Cost of employment</h3>
          <p className="outcome-text-coe">
            Indication of changed annual cost of employment as a result of
            position adjustment(s).
            <strong className="coe-value">
              {sign}
              {coe}
            </strong>
          </p>
        </div>
        <h3 className="outcome-title-right">Further Information​</h3>
        <ul className="outcome-links">
          <li>
            <Link
              to={{
                pathname: settingManagementLevelsAtShell,
              }}
              target="_blank"
            >
                <div className="link-to">
                    <span className="icon-wrapper">
                    <DocumentLinkIcon />
                    </span>
                   <span className="link-text">Setting Management levels at Shell</span>
                </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: spanOfControlLink,
              }}
              target="_blank"
            >
                   <div className="link-to">
                    <span className="icon-wrapper">
                    <VideoLinkIcon />
                    </span>
                   <span className="link-text">Span of Control</span>
                  </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: managementLevelCompression,
              }}
              target="_blank"
            >
                  <div className="link-to">
                    <span className="icon-wrapper">
                    <VideoLinkIcon />
                    </span>
                   <span className="link-text">Management Level Compression</span>
                  </div>
              </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: organisationLayers,
              }}
              target="_blank"
            >
                
                <div className="link-to">
                    <span className="icon-wrapper">
                    <VideoLinkIcon />
                    </span>
                   <span className="link-text">Organisational Layers</span>
                </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: managementLevelProbabilityAnalysis,
              }}
              target="_blank"
            >
                 <div className="link-to">
                    <span className="icon-wrapper">
                    <DocumentLinkIcon />
                    </span>
                   <span className="link-text">Management Level PROBABILITY Analysis</span>
                </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: evaUserGuide,
              }}
              target="_blank"
            >
                <div className="link-to">
                    <span className="icon-wrapper">
                    <DocumentLinkIcon />
                    </span>
                   <span className="link-text">Eva User Guide (Full)</span>
                </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: hrAdviceRepresentative,
              }}
              target="_blank"
            >
                <div className="link-to">
                    <span className="icon-wrapper">
                    <LinkIcon />
                    </span>
                   <span className="link-text">Hr Advice Representative</span>
                </div>
            </Link>
          </li>
          <li>
            <Link
              to={{
                pathname: systemSupportLink,
              }}
              target="_blank"
            >
                
                <div className="link-to">
                    <span className="icon-wrapper">
                    <LinkIcon />
                    </span>
                   <span className="link-text">HR Systems support</span>
                </div>
            </Link>
          </li>
        </ul>
        {jdLinksContent && isSubmitted && (
          <h3 className="outcome-title-right">Uploaded Job Descriptions</h3>
        )}
        <ul className="outcome-links">{jdLinksContent}</ul>
      </div>
    </section>
  );
};

export default OutcomeRight;
