import { useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

import uploadFileToAzureBlob from "../../config/azureconfig.ts";
import { selectAuth } from "../../app/features/authSlice";
import { onUploadJdFile } from "../../app/features/lineManagerSlice";

const Upload = (props) => {
  const files = props.filesArray;
  const { name } = useSelector(selectAuth);

  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState("");
  const [isUploaded, setIsUploaded] = useState(false);

  const dispatch = useDispatch();

  let renderList = null;
  if (props.error.length !== 0) {
    renderList = <span className="error">{props.error}</span>;
  }
  if (props.error.length === 0 && !files) {
    renderList = <span className="files__item__name">No file found</span>;
  } else if (props.error.length === 0 && files.length !== 0) {
    renderList = files.map((file, index) => {
      let suffix = "bytes";
      let size = file.size;
      let validFile = true;
      if (size > 2 * 1024000) {
        validFile = false;
      }
      if (size >= 1024 && size < 1024000) {
        suffix = "KB";
        size = Math.round((size / 1024) * 100) / 100;
      } else if (size >= 1024000) {
        suffix = "MB";
        size = Math.round((size / 1024000) * 100) / 100;
      }

      return (
        <li className="files__item" key={index}>
          <div className="file__item__container">
            <span className="files__item__name">
              {file.name}
              <span
                className={`files__item__size ${validFile ? "" : "danger"}`}
              >
                ({size} {suffix})
              </span>
            </span>
          </div>
        </li>
      );
    });
  }

  const dateOfSubmission = () => {
    const today = new Date();
    return today.toISOString();
  };

  const getTime = () => {
    return new Date().toUTCString();
  };

  const handleOnUpload = async (e) => {
    e.preventDefault();

    if (
      props.file &&
      props.file.type !==
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setError("File type not supported");
    } else if (props.file && props.file.size > 1024000) {
      setError("File Size should be less than 1MB");
    } else if (props.file) {
      const fileName = props.file.name.split(/\s/).join("");
      const file = new File([props.file], `${dateOfSubmission()}-${fileName}`, {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      setUploading(true);

      try {
        const blobsInContainer = await uploadFileToAzureBlob(file);
        const addFileToContainer = await axios.post(
          `${process.env.REACT_APP_API_URL}/add-unwanted-files`,
          {
            file: blobsInContainer[0],
            loadTime: getTime(),
            modifiedTime: getTime(),
            loadUser: name,
            modifiedUser: name,
          }
        );

        await Promise.all([blobsInContainer, addFileToContainer]);

        props.onUploadJD(blobsInContainer);
        setUploading(false);

        blobsInContainer.length !== 0 && setIsUploaded(true);
        dispatch(onUploadJdFile(file.name));

        // console.log("[files]", file.name, blobsInContainer[0]);
      } catch (error) {
        // console.log(error);
        if (error) {
          setError("something wrong!!");
          setUploading(false);
        }
      }
    }
  };

  return (
    <div className="upload">
      <div className="upload__container">
        <label
          htmlFor="upload"
          className={`${
            props.disabled ? "disabled-background" : "upload__label "
          }`}
        >
          <input
            disabled={props.disabled}
            type="file"
            id="upload"
            name={props.name}
            onChange={(e) => {
              setError("");
              setIsUploaded(false);
              props.onChange(e);
            }}
          />
          Choose file to Upload
        </label>
        <div className="files">
          <ul className="files__list">
            {!uploading && !error && renderList}
            {!uploading && <p className="jd-error-message">{error}</p>}
            {uploading && <div className="loader-small"></div>}
            {isUploaded && (
              <p className="jd-success-message">
                &#10003; JD Uploaded successfully!
              </p>
            )}
            {!isUploaded && uploading && (
              <p className="jd-loading-message">Uploading</p>
            )}
          </ul>
        </div>

        <button className="upload-button" onClick={handleOnUpload}>
          UPLOAD
        </button>
      </div>
    </div>
  );
};

export default Upload;
