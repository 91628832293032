import React from "react";

const HrIcon = () => {
  return (
    <div className="hr-icon">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <path
          fill="#9BC9FF"
          d="M505.751,475.563l-90.815-90.815c33.944-40.71,54.397-93.04,54.397-150.064
	c0-129.395-105.271-234.667-234.667-234.667S0,105.287,0,234.684s105.271,234.667,234.667,234.667
	c57.041,0,109.389-20.467,150.103-54.43l90.813,90.813c4.166,4.166,9.626,6.249,15.084,6.249s10.92-2.082,15.084-6.249
	C514.084,497.401,514.084,483.893,505.751,475.563z"
        />
        <path
          fill="#BDFDFF"
          d="M234.667,42.684c-105.869,0-192,86.13-192,192c0,105.869,86.131,192,192,192s192-86.131,192-192
	C426.667,128.814,340.535,42.684,234.667,42.684z M135.111,334.239c0-54.987,44.574-99.556,99.556-99.556
	c-35.345,0-64.001-28.654-64.001-64s28.656-64,64.001-64s64,28.652,64,63.999s-28.655,64-64,64c54.98,0,99.554,44.568,99.554,99.556
	h-199.11V334.239z"
        />
        <circle fill="#EFC27B" cx="234.667" cy="170.68" r="64" />
        <path
          fill="#5286FA"
          d="M234.667,234.684c-54.982,0-99.556,44.568-99.556,99.556h99.556h99.554
	C334.221,279.252,289.647,234.684,234.667,234.684z"
        />
        <path
          fill="#ECB45C"
          d="M170.665,170.682c0,35.348,28.656,64,64.001,64v-128
	C199.322,106.684,170.665,135.336,170.665,170.682z"
        />
        <path
          fill="#3D6DEB"
          d="M135.111,334.239h99.556v-99.556C179.685,234.684,135.111,279.252,135.111,334.239z"
        />
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
        <g></g>
      </svg>
    </div>
  );
};

export default HrIcon;
