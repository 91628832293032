import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import App from "./App";
import store from "./app/store";
import "./sass/main.scss";

import { AuthProvider } from "./services/AuthProvider";
import { AuthService } from "./services/AuthService";

const authService = new AuthService({
  clientId: process.env.REACT_APP_SSO_CLIENT_ID,
  location: window.location,
  authEndPoint: process.env.REACT_APP_AUTH_END_POINT,
  tokenEndPoint: process.env.REACT_APP_TOKEN_END_POINT,
  redirectUri:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/authcallback"
      : process.env.REACT_APP_SSO_REDIRECT_URI,
  scopes: ["openid", "profile"],
});

// console.log("auth-service", authService);

ReactDOM.render(
  <Router basename={process.env.PUBLIC_URL}>
    <React.StrictMode>
      <AuthProvider authService={authService}>
        <Provider store={store}>
          <App />
        </Provider>
      </AuthProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);
