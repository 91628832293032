import Select from "react-select";

const colourStyles = {
  menuList: (styles) => ({
    ...styles,
    background: "white",
  }),
  option: (styles, { isFocused, isSelected }) => ({
    ...styles,
    background: isFocused ? "#fbce07" : isSelected ? "#f8a94c" : undefined,
    fontFamily: "Shell-Book",
    fontWeight: 800,
    fontSize: ".75rem",
  }),
};

const SelectComponent = (props) => {
  return (
    <Select
      onFocus={props.onFocus}
      onBlur={props.onBlur}
      isDisabled={props.disabled}
      isClearable
      name={props.name}
      value={props.value}
      onChange={(selectedOption) => props.onChange(selectedOption)}
      options={props.options}
      className={`form__select ${props.className} ${
        props.showError && (props.value === "" || !props.value)
          ? "form__select__error"
          : ""
      }`}
      styles={colourStyles}
    />
  );
};

export default SelectComponent;
