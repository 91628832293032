import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Button from "../../components/button/Button";

import LineManager from "../../containers/line-manager/LineManager";
import OshLinks from "../../containers/osh-links/OshLinks";
import OshTable from "../../containers/osh-table/OshTable";
import Coe from "../../containers/coe/Coe";
import Teams from "../../containers/teams/Teams";
import AiBox from "../../containers/ai-box/AiTable";

import { useSelector } from "react-redux";
import { selectAuth } from "../../app/features/authSlice";
import { selectLineManager } from "../../app/features/lineManagerSlice";

const Home = (props) => {
  const {
    positionId,
    positionTitle,
    location,
    jobProfile,
    spanOfControlOfTeam,
    compressionOfTeam,
    maxNoOfLayersOfTeam,
    spanOfControlOfTeamToBe,
    maxNoOfLayersOfTeamToBe,
    sameJGReportingsInTeamOfLineManager,
  } = useSelector(selectAuth);
  const {
    teamDetails,
    error,
    noOfChanges,
    compressionOfTeamToBe,
    coeImpact,
    changes,
  } = useSelector(selectLineManager);

  const history = useHistory();

  const aiChanges = [];
  if (changes.length !== 0) {
    changes.map((v) => {
      if (!v.isDuplicated && !v.isRemoved) {
        aiChanges.push(v);
      }
      return aiChanges;
    });
  }

  // console.log(aiChanges);

  let homePage = <h2>No teams found.....</h2>;

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    homePage = (
      <h1 className="error-message">
        Something Went Wrong!! Please try after sometime
      </h1>
    );
  } else if (!error && teamDetails !== null) {
    homePage = (
      <div className="home">
        <section className="positions-container">
          <LineManager
            positionId={positionId}
            positionTitle={positionTitle}
            location={location}
            jobProfile={jobProfile}
          />
          <div className="team-container">
            <Teams teamDetails={teamDetails} noOfChanges={noOfChanges} />
          </div>
          <div className="footer-buttons">
            <Button
              size="medium"
              disabled={noOfChanges === 0}
              onClick={() => history.push("/outcome")}
            >
              Next
            </Button>
          </div>
        </section>
        <section className="tables-container">
          <div className="osh-table">
            <OshTable
              spanOfControl={spanOfControlOfTeam}
              jgCompression={parseFloat(compressionOfTeam.toFixed(2))}
              sameJGReportings={sameJGReportingsInTeamOfLineManager}
              maxNoOfLayers={maxNoOfLayersOfTeam}
              spanOfControlToBe={spanOfControlOfTeamToBe}
              jgCompressionToBe={compressionOfTeamToBe}
              maxNoOfLayersToBe={maxNoOfLayersOfTeamToBe}
            />
          </div>
          {aiChanges.length === 0 ? null : (
            <div className="cost-table">
              <AiBox />
            </div>
          )}
          {coeImpact === 0 && noOfChanges === 0 ? null : (
            <div className="cost-table">
              <Coe coeImpact={coeImpact} />
            </div>
          )}

          <div className="links-table-osh">
            <OshLinks />
          </div>
        </section>
      </div>
    );
  }

  return homePage;
};

export default Home;
