import React from "react";
import { useSelector } from "react-redux";

import { selectAuth } from "../../app/features/authSlice";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import ChangedPosition from "../changed-position/ChangedPosition";
import LineManagerPosition from "../line-manager-position/LineManagerPosition";
import RedArrowMedium from "../../components/icons/red-arrow-medium/RedArrowMedium";
import RedArrowBigPng from "../../components/icons/red-arrow-big/RedArrowBig";
import {
  overallFinalTextAmber,
  overallFinalTextGreen,
  overallFinalTextRed,
} from "../outcome-sheet-texts/OutcomeSheetTexts";

const OutcomeLeft = (props) => {
  const {
    overallOutCome,
    isSubmitted,
    compression,
    topWeight,
    managementLevelOneBelow,
    jgDistribution,
    id,
    nextStepsForOutcome,
    sameJGReportingsTobe,
    outComeButtons,
  } = props;
  const {
    positionId,
    positionTitle,
    location,
    jobProfile,
    sameJGReportingsInTeam,
    compressionOfTeam,
    maxNoOfLayers,
    spanOfControlOfTeamToBe,
    adminParameters,
  } = useSelector(selectAuth);

  const { teamDetails, changes } = useSelector(selectLineManager);
  const showReasons = teamDetails.filter(
    (position) =>
      position.isChanged ||
      position.isNewPosition ||
      position.isDuplicated ||
      position.isRemoved
  );

  const {
    compressionORANGEUPPERGreaterAndEqualTo,
    compressionORANGEUPPERLessAndEqualTo,
    compressionORANGELOWERGreaterAndEqualTo,
    compressionORANGELOWERLessAndEqualTo,
    compressionREDGreaterAndEqualTo,
    compressionREDLessAndEqualTo,
    layersORANGEGreaterAndEqualTo,
    layersORANGELessAndEqualTo,
    spanOfControlORANGEGreaterAndEqualTo,
    spanOfControlORANGELessAndEqualTo,
    spanOfControlREDGreaterAndEqualTo,
    spanOfControlREDLessAndEqualTo,
    topWeightREDGreaterAndEqualTo,
    sameJobGroupReportingGREENLessAndEqualTo,
    spreadOverJGsORANGELessAndEqualTo,
  } = adminParameters;

  const aiChanges = [];
  if (changes.length !== 0) {
    changes.map((v) => {
      if (!v.isDuplicated && !v.isRemoved) {
        aiChanges.push(v);
      }
      return aiChanges;
    });
  }
  // ....................................................

  // ....................................................
  let outcomeText = "";
  if (overallOutCome === "green") {
    outcomeText =
      "The new team structure seems sufficiently healthy to accommodate the changes you have modelled.​ Note that you may be asked for further information on any of the changed positions as part of our audit process.";
  } else if (overallOutCome === "amber") {
    outcomeText =
      "EVA notices potential concerns in the health of your team structure and/or the Management Level(s) you have selected for new or changed positions.​ Please reflect with your Line Manager on the EVA observations and recommendations stated below and adjust as you see fit. Note that you may be asked for further information on any of the changed positions as part of our audit process.";
  } else {
    outcomeText =
      "EVA notices major potential concerns in the health of your team structure and/or the Management Level(s) you have selected for new or changed positions. These concerns are specified below.​ If you still wish to proceed with the modelled set-up, your request will be escalated to the central Job Evaluation team if submitted to HR advice or raised in Workday. We suggest to discuss with your Line Manager and reconsider.";
  }

  let aiSupplimentaryTexts = [];

  aiChanges.length !== 0 &&
    aiChanges.map(
      ({
        id,
        positionTitle,
        positionId,
        initialManagementLevel,
        ManagementLevel,
        aiManagementLevel,
        confidence,
        isChanged,
        jobProfile,
        cousinProfiles,
      }) => {
        // COUSIN PROFILE LOGIC

        const generic = `00000000`;

        if (jobProfile.includes(generic) && cousinProfiles) {
          aiSupplimentaryTexts.push(
            <React.Fragment>
              <li key={id}>
                <span className="outcome-bullet-arrow">
                  <RedArrowMedium />
                </span>

                <p className="outcome-next-step">
                  {`For position ${positionTitle} you have selected a generic profile at ML ${aiManagementLevel}. Please note that when creating this position in Workday you will be requested to select a job profile from the job catalogue. In the same job family the following profiles are available at the selected ML ${aiManagementLevel}.`}
                </p>
              </li>
              {cousinProfiles.length !== 0 ? (
                cousinProfiles.map((e, i) => {
                  return (
                    <div className="nested-outcome-next-steps" key={i}>
                      <span className="nested-outcome-bullet-arrow">
                        <RedArrowMedium />
                      </span>
                      <p className="nested-outcome-next-step">
                        {e.Job_Profile_With_Job_ID}
                      </p>
                    </div>
                  );
                })
              ) : (
                <h3 className="sub-text">
                  No other Job Profiles found at this Management Level
                </h3>
              )}
            </React.Fragment>
          );
        }
        // END OF COUSIN PROFILES LOGIC
        if (
          confidence === "medium" &&
          ManagementLevel === aiManagementLevel &&
          aiManagementLevel < initialManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            <li key={id}>
              <span className="outcome-bullet-arrow">
                <RedArrowMedium />
              </span>
              <p className="outcome-next-step">
                {`For position ${positionTitle} ${
                  isChanged ? ` - ${positionId}` : ""
                }: You have adjusted your original Management Level selection upwards. Alternatively, you could consider rebalancing accountabilities within your team and/or slightly reduce the requirements for this position to stay with your initial selected Management Level ${initialManagementLevel}.`}
              </p>
            </li>
          );
        } else if (
          // (confidence === "medium" && ManagementLevel > aiManagementLevel) ||
          confidence === "high" &&
          ManagementLevel > aiManagementLevel &&
          aiManagementLevel < initialManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            <li key={id}>
              <span className="outcome-bullet-arrow">
                <RedArrowMedium />
              </span>
              <p className="outcome-next-step">
                {`For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: Review the accountabilities of the role as the position information contained elements of a higher Management Level. Ensure to align the accountabilities with the Management Level. You may use the Job Profile that you selected as a reference point.`}
              </p>
            </li>
          );
        } else if (
          confidence === "high" &&
          ManagementLevel < aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            <li key={id}>
              <span className="outcome-bullet-arrow">
                <RedArrowMedium />
              </span>
              <p className="outcome-next-step">
                {`For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}:  EVA analysis suggests a lower level than this level you selected. Consider lowering Management Level accordingly.`}
              </p>
            </li>
          );
        } else if (
          confidence === "high" &&
          ManagementLevel > aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            <li key={id}>
              <span className="outcome-bullet-arrow">
                <RedArrowMedium />
              </span>
              <p className="outcome-next-step">
                {`For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: EVA analysis suggests that the responsibilities of the position are heavy in comparison to the Management Level you selected. Consider re-allocation of accountabilities. You may use the Job Profile that you selected as a reference point.`}
              </p>
            </li>
          );
        } else if (
          confidence === "medium" &&
          ManagementLevel < aiManagementLevel
        ) {
          aiSupplimentaryTexts.push(
            <li key={id}>
              <span className="outcome-bullet-arrow">
                <RedArrowMedium />
              </span>
              <p className="outcome-next-step">
                {`For Position ${`
                      ${positionTitle}
                      ${isChanged ? ` - ${positionId}` : ""}
                   `}: The position information showed significant elements of a lower Management Level. Consider whether the Management Level can be reduced and ensure the allocation of accountabilities aligns.`}
              </p>
            </li>
          );
        }
        return aiSupplimentaryTexts;
      }
    );

  // useEffect(() => {
  //   if (!isSubmitted && !id) {
  //     const customButtonClasses = Swal.mixin({
  //       customClass: {
  //         confirmButton: "change-button warning",
  //         container: "swal-wide",
  //       },
  //       buttonsStyling: false,
  //     });
  //     customButtonClasses.fire({
  //       html: `<p style="margin:0;font-size:15px;text-align:left;padding:10px 0 0 5px;font-weight:700;">EVA reflects the organisational structure as it is recorded in Workday (weekly refresh). After Reshape go-live some Line Managerial positions have an incorrect number of direct reports, in most cases due to vacant Line Manager positions deeper in their organisation and/or SRR movements.</p>
  //              <p style="margin:0;font-size:15px;text-align:left;padding:15px 0 0 5px;font-weight:700;">This could affect the outcomes of your EVA modelling if you have a significant difference (in number of positions and/or grade distribution) compared to the target design.</p>
  //              <p style="margin:0;font-size:15px;text-align:left;padding:15px 0 0 5px;font-weight:700;">In case of a significant impact to your ability to model changes in EVA, or errors in your reporting line (unless vacancy is pending SRR appointment effective October 1st or other planned movement), please reach out to HR Advice using the help button.</p>
  //              <p style="margin:0;font-size:15px;text-align:left;padding:15px 0 0 5px;font-weight:700;">Thank you for your understanding. </p>`,
  //       confirmButtonText: "OK",
  //       cancelButtonText: "Cancel",
  //       width: "750px",
  //     });
  //   }
  // }, [isSubmitted, id]);

  let outcomeTextFinal = "";
  if (overallOutCome === "green") {
    outcomeTextFinal = overallFinalTextGreen;
  } else if (overallOutCome === "amber") {
    outcomeTextFinal = overallFinalTextAmber;
  } else {
    outcomeTextFinal = overallFinalTextRed;
  }

  let spanOfControlSuplimentText = null;
  if (
    (spanOfControlOfTeamToBe >= spanOfControlREDGreaterAndEqualTo &&
      spanOfControlOfTeamToBe <= spanOfControlREDLessAndEqualTo) ||
    spanOfControlOfTeamToBe === 0
  ) {
    spanOfControlSuplimentText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your span of control is significantly lower than best practice.
          Reflect with your Line Manager whether there are opportunities in the
          wider organisation to optimise team compositions.
        </p>
      </li>
    );
  } else if (
    spanOfControlOfTeamToBe >= spanOfControlORANGEGreaterAndEqualTo &&
    spanOfControlOfTeamToBe <= spanOfControlORANGELessAndEqualTo
  ) {
    spanOfControlSuplimentText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your span of control is significantly lower than best practice.
          Reflect with your Line Manager whether there are opportunities in the
          wider organisation to optimise team compositions.
        </p>
      </li>
    );
  }

  let sameJgText = null;
  if (
    sameJGReportingsInTeam !== null &&
    sameJGReportingsTobe !== sameJobGroupReportingGREENLessAndEqualTo
  ) {
    sameJgText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Within your team you have same grade reporting (where one or more
          positions are at the same Management Level as your own), which is an
          undesired set-up. A minimum of one Management Level difference is
          expected, best practice is two levels difference on average.
        </p>
      </li>
    );
  }

  let compressionSuggestionText = null;
  let compressionAsIs = compressionOfTeam.toFixed(2);
  if (
    compression >= compressionORANGELOWERGreaterAndEqualTo &&
    compression <= compressionORANGELOWERLessAndEqualTo &&
    compression > compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your team compression improves after the change(s), yet remains lower
          than best practice. There may be opportunities to further rebalance
          seniority and reduce costs.
        </p>
      </li>
    );
  } else if (
    compression >= compressionORANGELOWERGreaterAndEqualTo &&
    compression <= compressionORANGELOWERLessAndEqualTo &&
    compression < compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your team compression decreases after the change(s), further below
          best practice. Reconsider opportunities to rebalance seniority and
          reduce costs.
        </p>
      </li>
    );
  } else if (
    compression >= compressionORANGELOWERGreaterAndEqualTo &&
    compression <= compressionORANGELOWERLessAndEqualTo &&
    compression === compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your team compression after the change(s) remains lower than best
          practice. There may be opportunities to rebalance seniority and reduce
          costs.
        </p>
      </li>
    );
  } else if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo &&
    compression > compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your team compression improves after the change(s), yet remains
          significantly below best practice. Continue to look for opportunities
          to rebalance seniority and reduce costs.
        </p>
      </li>
    );
  } else if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo &&
    compression < compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your change(s) result in a compression below the minimum threshold.
          Reconsider the balance of seniority in the team and increase the
          proportion of positions with two Management Levels' difference to your
          own position.
        </p>
      </li>
    );
  } else if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo &&
    compression === compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your change(s) result in a compression below the minimum threshold.
          Reconsider the balance of seniority in the team and increase the
          proportion of positions with two Management Levels difference to your
          own position.
        </p>
      </li>
    );
  } else if (
    compression >= compressionORANGEUPPERGreaterAndEqualTo &&
    compression <= compressionORANGEUPPERLessAndEqualTo &&
    compression <= compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your team compression after the change(s) remains relatively high.
          This situation indicates a significant difference between your
          managerial focus versus that of your team members.
        </p>
      </li>
    );
  } else if (
    compression >= compressionORANGEUPPERGreaterAndEqualTo &&
    compression <= compressionORANGEUPPERLessAndEqualTo &&
    compression > compressionAsIs
  ) {
    compressionSuggestionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Your change(s) result in a relatively high compression, indicating
          there is a significant difference between your managerial focus versus
          that of your team members.
        </p>
      </li>
    );
  }

  let maxNoOfLayersText = null;
  if (
    maxNoOfLayers >= layersORANGEGreaterAndEqualTo &&
    maxNoOfLayers <= layersORANGELessAndEqualTo
  ) {
    maxNoOfLayersText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Reflect with your Line Manager whether there are opportunities in the
          wider organisation to reduce the number of organisational layers.
        </p>
      </li>
    );
  }

  let topWeightText = null;
  if (topWeight >= topWeightREDGreaterAndEqualTo) {
    topWeightText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          Consider changing the Management Level distribution in your team as
          Management Level{" "}
          <strong> {managementLevelOneBelow[0].managementLevel} </strong>{" "}
          positions seem to be over-represented.
        </p>
      </li>
    );
  }

  let jgDistributionText = null;
  if (jgDistribution.length === spreadOverJGsORANGELessAndEqualTo) {
    jgDistributionText = (
      <li>
        <span className="outcome-bullet-arrow">
          <RedArrowMedium />
        </span>
        <p className="outcome-next-step">
          All your direct reports are at the same Management Level. A mix of
          Management Levels enables staff development, (peer) coaching and
          succession planning within the team.
        </p>
      </li>
    );
  }

  let outcomeIntroductoryText = (
    <p className="outcome-text">
      This is a summary of the change(s) you have modelled. On the right, the
      overall outcome indicates any potential concerns from EVA’s perspective.​
      Please note the observations and recommendations below.​ After reviewing,
      you can choose to remodel or finalise your modelling session. If you
      choose to finalise, a copy of this summary will also be sent to your Line
      Manager and a PDF will be made available to you.
    </p>
  );

  if (isSubmitted) {
    outcomeIntroductoryText = null;
  }

  return (
    <React.Fragment>
      <section className="left-side-outcome">
        {outcomeIntroductoryText}
        {isSubmitted && id !== null ? (
          <article className="outcome-next-steps">
            <h3 className="outcome-left-main-main-title">ACTIONS REQUIRED</h3>
            {overallOutCome === "amber" ? (
              <React.Fragment>
                <p
                  className="outcome-next-step"
                  style={{ marginLeft: "10px", display: "block" }}
                >
                  With your Line Manager reflect on the EVA observations and
                  recommendations below and remodel if needed.
                </p>
                <p className="outcome-next-step" style={{ marginLeft: "10px" }}>
                  If wish to proceed:
                </p>
              </React.Fragment>
            ) : null}
            <ul className="outcome-left-nextsteps">
              {nextStepsForOutcome.map((value, index) => (
                <li key={index}>
                  <span className="outcome-arrow-next">
                    <RedArrowBigPng />
                  </span>
                  <p className="outcome-next-step">{value}</p>
                </li>
              ))}
            </ul>
          </article>
        ) : null}
        {isSubmitted && id ? (
          <h1 className="outcome-left-main-title">Summary of Outcomes</h1>
        ) : null}

        <h3 className="outcome-left-title">
          Observations and Recommendations​
        </h3>
        <p className="outcome-text util-padding">
          {isSubmitted ? outcomeTextFinal : outcomeText}
        </p>
        <ul className="outcome-left-texts">
          {sameJgText}
          {compressionSuggestionText}
          {spanOfControlSuplimentText}
          {maxNoOfLayersText}
          {topWeightText}
          {jgDistributionText}
          {aiSupplimentaryTexts}
        </ul>
        <div
          className={
            isSubmitted && id
              ? "outcome-team-structure-final"
              : "outcome-team-structure"
          }
        >
          <div className="outcome-linemanager">
            <span className="outcome-text margin-util-text">
              To be Team structure
              <div className="legend ">
                <span className="legend-box manager"></span>
                <p className="outcome-card-text">- Managerial Position</p>
              </div>
              <div className="legend ">
                <span className="legend-box individual"></span>
                <p className="outcome-card-text">- Individual contributor</p>
              </div>
            </span>

            <LineManagerPosition
              isManager={true}
              positionTitle={positionTitle}
              positionId={positionId}
              jobProfile={jobProfile}
              location={location}
            />
            <div className="legends-positions">
              <div className="legend ">
                <span className="legend-box removed-position-border"></span>
                <p className="outcome-card-text">- Removed position</p>
              </div>
              <div className="legend ">
                <span className="legend-box new-position-border"></span>
                <p className="outcome-card-text">- New position</p>
              </div>
              <div className="legend ">
                <span className="legend-box duplicated-position-border"></span>
                <p className="outcome-card-text">- Duplicated Position</p>
              </div>
              <div className="legend ">
                <span className="legend-box changed-position-border"></span>
                <p className="outcome-card-text">- Changed Position</p>
              </div>
            </div>
          </div>
          <div className="outcome-team">
            {teamDetails.map((position) => {
              let output = null;
              if (!position.isActionNotAllowed) {
                output = (
                  <ChangedPosition
                    key={position.id}
                    id={position.id}
                    isManager={position.isManager}
                    managementLevel={position.ManagementLevel}
                    positionTitle={position.positionTitle}
                    positionId={position.positionId}
                    jobProfile={position.jobProfile}
                    location={position.location}
                    isUnfilled={position.positionUnfilled}
                    isEditAndDeleteAllowed={position.editAndDeleteAllowed}
                    isRemoved={position.isRemoved}
                    isNewPosition={position.isNewPosition}
                    isEdited={position.isChanged}
                    isDuplicated={position.isDuplicated}
                    coeOfPosition={position.coeOfPosition}
                    showBlue={position.showBlue}
                  />
                );
              }
              return output;
            })}
          </div>
          {showReasons.length !== 0 ? (
            <div className="outcome-reasons">
              <h3 className="outcome-reasons-title">Reason for Action(s) : </h3>
              <ul className="outcome-reasons-list">
                {teamDetails.map((position) => {
                  let content = null;
                  if (position.comments) {
                    content = (
                      <li key={position.positionId}>
                        <span className="outcome-bullet-arrow">✻</span>
                        <p className="outcome-reason">
                          Position{" "}
                          <strong className="extra-strong">
                            {position.positionTitle}
                            {position.isChanged || position.isRemoved
                              ? ` - ${position.positionId}`
                              : null}{" "}
                          </strong>
                          : {position.comments}
                        </p>
                      </li>
                    );
                  }
                  return content;
                })}
              </ul>
            </div>
          ) : null}
        </div>
        {outComeButtons}
      </section>
    </React.Fragment>
  );
};

export default OutcomeLeft;
