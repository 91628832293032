import React from "react";

import JobDescription from "../../icons/job-description/JobDescription";

const JobDescriptionLink = (props) => {
  return (
    <div className="link-to">
      <span className="icon-container-jd">
        <JobDescription />
      </span>
      <span className="link-text">{props.children}</span>
    </div>
  );
};

export default JobDescriptionLink;
