
export const jobTitleTextLabel =
  "Select from the existing Job Profiles used in your team or select from the broader list of available Job Family Groups.";

export const jdTextLabel =
  "Please upload the job description. Note that you may be asked for further information on any of the changed positions as part of our audit process";

export const overallFinalTextRed =
  "EVA notices major potential concerns in the health of your team structure and/or the Management Level(s) you have selected for new or changed positions. These concerns are specified below.​ If you still wish to proceed with the modelled set-up, your request will be escalated to the central Job Evaluation team if submitted to HR advice or raised in Workday. We suggest to discuss with your Line Manager and reconsider.";

export const overallFinalTextAmber =
  "EVA notices potential concerns in the health of your team structure and/or the Management Level(s) you have selected for new or changed positions.​ Please reflect with your Line Manager on the EVA observations and recommendations stated below and adjust as you see fit. Note that you may be asked for further information on any of the changed positions as part of our audit process.";

export const overallFinalTextGreen =
  "The new team structure seems sufficiently healthy to accommodate the changes you have modelled.​Note that you may be asked for further information on any of the changed positions as part of our audit process. ";


export const getEmailBody = (
  overalloutCome,
  superVisorName,
  overallOutComeTag,
  emailOverallOutcomeText,
  positionTitle,
  positionId,
  jobProfile,
  location,
  teamStructureForOutcomeEmail,
  colorCodeSpanControlAsIs,
  colorCodeSpanControlToBe,
  spanOfControlOfTeam,
  spanOfControlOfTeamToBe,
  directionOfChange,
  directionOfChangeForCompression,
  compressionOfTeam,
  compression,
  colorCodeMaxLayer,
  maxNoOfLayersOfTeam,
  maxNoOfLayersOfTeamToBe,
  sign,
  coe,
  sameJGReportingsInTeam,
  jobDescriptions,
  jobDescriptionLinks,
  outcomeColor,
  colorCodeCompression,
  colorCodeCompressionTobe,
  reasonForActionsBody,
  emailNextStepsForOverallOutcome,
  sameJGReportingsTobe,
  compressionTextOutcomeEmail,
  spanOfControlOutcomeTextEmail,
  jgDistributionTextOutcomeEmail,
  topWeightTextOutcomeEmail,
  sameJGReportingTextEmail,
  maxNoOfLayersTextEmail,
  aiSupplimentaryTextsForEmail,
  ragIndicatorTable,
  cousinProfileTextForEmail
) => {
//   console.log(sameJGReportingTextEmail);
//   console.log("OUTCOME OVEALL", overalloutCome);
  let amberoutCome;
  if (overalloutCome === "amber"){
   amberoutCome =`  <th style="vertical-align:top; margin-left:30px"></th>
                    <th>
                     <p style="margin:0;font-size:12.75px;text-align:justify">With your Line Manager reflect on the EVA observations and recommendations below and remodel if needed.
                    </p>
                    <p style="margin:0;font-size:12.75px;text-align:justify">If wish to proceed:</p>
                    </th>
                    </tr>`
}
//   }style="marginLeft: 10px;font-family: Shell-Medium; font-color:red; padding-bottom: 0px;display: inline-block;font-weight: 650; font-size: .825rem;
  else{
      amberoutCome = ``;
  }
  return `<!DOCTYPE html>
<html lang="en" xmlns="http://www.w3.org/1999/xhtml" xmlns:o="urn:schemas-microsoft-com:office:office">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width,initial-scale=1">
    <meta name="x-apple-disable-message-reformatting">
    <title></title>
    <style>
        table,
        td,
        td div,
        h1,
        p {
            font-family: ShellMedium;
        }
    </style>
</head>

<body style="margin:0;padding:0;font-family:ShellBook,sans-serif">
    <table role="presentation" align="center"
        style="width:840px;border-collapse:collapse;border:0;border-spacing:0;background:#f7f7f7;">
        <tr>
            <td style="padding:0;background:#fbce07;">
                <table role="presentation"
                    style="width:840px;border-collapse:collapse;border:0;border-spacing:0;font-size:9px;">
                    <tr>

                        <td style="padding:0;">
                            <table role="presentation" style="border-collapse:collapse;border:0;border-spacing:0;">
                                <tr style="padding: 0;">
                                    <td align="center" style="padding:0;background-color: #ffffff;">
                                        <a href="https://www.shell.com" style="color:#ffffff;"><img
                                                src="https://logodownload.org/wp-content/uploads/2014/07/shell-logo-0.png"" alt="
                                                Shell" width="65" style="height:auto;display:block;border:0;" /></a>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        <tr>
            <td style="padding:16px 30px 15px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td style="padding:0;color:#153643;">
                            <h1 style="font-size:13px;margin:0 0 15px 0;">Dear
                                ${superVisorName},</h1>
                            <p style="margin:0;font-size:12px;text-align:justify;">
                                You are receiving this email because one of your direct reports has requested changes in the structure of his/her team.  
                                These changes have been modelled using the EVA tool – the guide to prepare Management Levels for new and changed positions at Management Levels 3-5.</p>
                        </td>
                    </tr>
                    <tr>
                        <td style="width:260px;vertical-align:top;color:#153643;padding:5px 0;;">
                            <table style="padding:0;width: 100%;margin: 0;">
                                <thead>
                                    <th style="font-size: 13px;padding:10px 0;color:${outcomeColor};text-align: left;">
                                        ${emailOverallOutcomeText} </th>
                                </thead>
                            </table>
                        </td>
                    </tr>
                    <tr>
                        <td style="padding:0 0 15px 0;color:#153643;">
                            <p style="margin:0;font-size:13px;color:#00000;text-align:justify;">
                                The summary of the changes and the recommendations that have been presented to the
                                requestor can be found below.</p>
                        </td>
                    </tr>

                    <tr>
            <td >
                <table role="presentation"
                    style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td>
                                        <p
                                            style="margin:0;font-size:13.5px;font-family:shellMedium;color:#00000;font-weight:600;text-align:justify;">
                                            This information is provided to you to ensure your awareness of the changes
                                            and the
                                            implications. Please discuss any concerns with your direct report.</p>
                         </td>
                     </tr>
                </table>
            </td>
         </tr>
        
        <tr>
         <td>
            <table role="presentation"
                style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                      <td style="width:260px;padding:0;vertical-align:top;color:#153643;">
                                    
                                        <p
                                            style="margin:10px 0;font-size:12px;color:black;font-weight:600;text-align:justify;">
                                            Thank you,</p>
                                        <p
                                            style="margin:10px 0;font-size:12px;color:black;font-weight:600;text-align:justify;">
                                            Shell Job Evaluation Team</p>
                                 </td>

                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
        
        <tr>
            <td style="padding:5px 30px 10px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td
                            style="width:260px;padding:15px 0 0 0;vertical-align:top;color:#153643;border-top: 0.5px solid #dd1d21;">
                            <h1 style="font-size: 15px; margin: 0;text-decoration: underline;color: #009eb4;">OUTCOME
                                SUMMARY AS GENERATED BY YOUR DIRECT REPORT</h1>
                        </td>

                    </tr>
                </table>
            </td>
        </tr>

                    <tr>
                    <td style="padding:15px 30px 5px 30px;">
                        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="padding:0;color:#153643;">
                                    ${
                                      sameJGReportingTextEmail ||
                                      compressionTextOutcomeEmail ||
                                      spanOfControlOutcomeTextEmail ||
                                      topWeightTextOutcomeEmail ||
                                      jgDistributionTextOutcomeEmail ||
                                      maxNoOfLayersTextEmail||
                                      aiSupplimentaryTextsForEmail
                                        ? `<h1 style="font-size: 14px; margin: 0 0 10px 0;">OBSERVATIONS
                                                    AND RECOMMENDATIONS:</h1>
                                    <table style="padding: 0;width: 100%;margin:0;">
                                        <tbody>${
                                          sameJGReportingTextEmail
                                            ? `
                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 5px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${sameJGReportingTextEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                            : ""
                                        } ${
                                            compressionTextOutcomeEmail
                                              ? `
                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${compressionTextOutcomeEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                              : ""
                                          } ${
                                            spanOfControlOutcomeTextEmail
                                              ? `
                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${spanOfControlOutcomeTextEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                              : ""
                                          } ${
                                            topWeightTextOutcomeEmail
                                              ? `

                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${topWeightTextOutcomeEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                              : ""
                                          } ${
                                            jgDistributionTextOutcomeEmail &&
                                            `
                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${jgDistributionTextOutcomeEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                          }${
                                            maxNoOfLayersTextEmail &&
                                            `
                                            <tr>
                                                <th align="center" style="vertical-align:top;"><span
                                                        style="font-size:15px;margin: 5px 5px 0 10px;color:#dd1d21">➥</span></th>
                                                <th style="margin:0 5px;">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        ${maxNoOfLayersTextEmail}
                                                    </p>
                                                </th>
                                            </tr>
                                            `
                                          }${
                                            aiSupplimentaryTextsForEmail.length !==
                                            0
                                              ? aiSupplimentaryTextsForEmail.join(
                                                  ""
                                                )
                                              : ""
                                          }${
                                            cousinProfileTextForEmail.length !==
                                            0
                                              ? cousinProfileTextForEmail.join(
                                                  ""
                                                )
                                              : ""
                                          }
                                        </tbody>
                                    </table>`
                                        : ""
                                    }
                                </td>
                            </tr>
                        </table>
                    </td>
                    </tr>
                    <tr>
                         <td style="padding:5px 30px 5px 30px;">
                        <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td style="width:450px;padding:0 20px 0 0;vertical-align:top;color:#153643;margin:0 10px;">
                                    <table style="padding: 0;width: 100%;margin: 0;">
                                        <thead>
                                            <th align="left" style="font-size: 12px;padding: 10px 15px;">
                                            </th>
                                            <th align="left" style="font-size: 12px;padding: 10px 15px;">
                                                TO BE TEAM STRUCTURE</th>

                                        </thead>
                                        <tbody>

                                            <tr>
                                                <th
                                                    style="padding:2px;border:1px solid #000;background-color:#d9d9d9;font-weight: bold;text-align: left">
                                                </th>
                                                <th
                                                    style="font-size: 12px;padding: 1.75px 8px;font-weight: bold;text-align: left">

                                                    - Individual Contributor
                                                </th>

                                            </tr>
                                            <tr>
                                                <th
                                                    style="padding:2px;border:1px solid #000;background-color:#89cfdc;font-weight: bold;text-align: left">
                                                </th>
                                                <th style=" font-size: 12px;padding: 2px 8px;text-align: left">

                                                    - Managerial Position
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style="width:850px;padding:0 53px 0 0;vertical-align:top;color:#153643;margin:0 33px;">
                                    <table style="padding: 0;width: 100%;">
                                        <thead>
                                            <tr>
                                                <th scope="col" style="font-size: 12px;padding: 10px 5px;">
                                                    LINE MANAGER</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th
                                                    style="border:1px solid #404040;padding: 7.5px 10px;background-color:#89cfdc;text-align: left;">
                                                    <p
                                                        style="margin:5px 0;font-size:12px;font-weight: bold;text-align:left;">
                                                        ${positionTitle}
                                                    </p>
                                                    <p style="margin:5px 0;font-size:11.5px;font-weight: 500;">
                                                        Position Number : ${positionId}
                                                    </p>
                                                    <p style="margin:5px 0;font-size:11.5px; font-weight: 500;">
                                                        Job Profile : ${jobProfile}
                                                    </p>
                                                    <p style="margin:5px 0;font-size:11.5px; font-weight: 500;">
                                                        Location : ${location}
                                                    </p>
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td style="width:460px;padding:0;vertical-align:top;color:#153643;margin:0 40px;">
                                    <table style="padding: 0;width: 100%;margin: 0;">
                                        <thead>

                                            <tr>
                                                <th style="font-size: 12px;padding: 10px 5px;width: 20px;">
                                                </th>
                                                <th scope="col" align="left" style="font-size: 12px;padding: 10px 5px;">
                                                    LEGENDS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th
                                                    style="padding:0;border:2px solid #6495ed;background-color:#e9e7e7;font-weight: bold;text-align: left">
                                                </th>
                                                <th style="font-size: 12px;padding:2px 8px;font-weight: bold;text-align: left">
                                                    - Changed Position</th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="padding:0;border:2px solid #800080;background-color:#e9e7e7;font-weight: bold;text-align: left">
                                                </th>
                                                <th style="font-size: 12px;padding:2px 8px;font-weight: bold;text-align: left">
                                                    - Duplicated Position</th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="padding:0;border:2px solid #0ab15e;background-color:#e9e7e7;font-weight: bold;text-align: left">
                                                </th>
                                                <th style="font-size: 12px;padding:2px 8px;font-weight: bold;text-align: left">
                                                    - New Position</th>
                                            </tr>
                                            <tr>
                                                <th
                                                    style="padding:0;border:2px solid #ff0000;background-color:#e9e7e7;font-weight: bold;text-align: left">
                                                </th>
                                                <th style="font-size: 12px;padding:2px 8px;font-weight: bold;text-align: left">
                                                    - Removed Position</th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td style="padding:5px 30px 5px 30px;">
                        <table role="presentation"
                            style="width:100%;table-layout:fixed;border-collapse:collapse;border:0;border-spacing:0;">
                            <tr>
                                <td align="center" style="padding: 0;">
                                    <table role="presentation"
                                        style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                                        ${teamStructureForOutcomeEmail.join("")}
                                    </table>
                                </td>
                            </tr>
                            <tr>
                            ${reasonForActionsBody}
                            </tr>
                        </table>
                    </td>
                </tr>

                
            <tr>
            <td style="padding:5px 30px 5px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                        <td style="width:260px;padding:0;vertical-align:top;color:#153643;margin:0 5px;">
                            <table style="padding: 0;width: 100%;margin: 0;">
                                <thead>
                                    <th style="font-size: 12px;padding: 10px 0px;background-color: #FFEAC2;">
                                        TEAM STRUCTURE HEALTH (DIRECT REPORTS)
                                    </th>
                                    <th scope="col"
                                        style="font-size: 12px;padding: 10px 5px;background-color: #FFEAC2;text-align: center;">
                                        AS IS
                                    </th>
                                    <th scope="col"
                                        style="font-size: 12px;padding: 10px 5px;background-color: #FFEAC2;text-align: center;">
                                        TO BE
                                    </th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th
                                            style="font-size: 11.5px;padding:5.5px 8px;background-color:#e9e7e7;font-family:ShellMedium;text-align: left">
                                            
                                             <a href="https://eu001-sp.shell.com/sites/AAAAA0313/HRBNP/Employee/Forms/AllItems.aspx?id=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation%2FSpan%20of%20Control%20Overview%2Epdf&parent=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 SPAN OF CONTROL
                                                            </a>
                                            </th>
                                        <td
                                            style="font-size: 14px;padding:5.5px 10px;background-color:${colorCodeSpanControlAsIs};text-align: center;font-family:ShellMedium;">
                                            ${spanOfControlOfTeam} </td>
                                        <td
                                            style="font-size: 14px;padding:5.5px 6px;background-color: ${colorCodeSpanControlToBe};text-align: center;font-family:ShellMedium;">
                                            ${spanOfControlOfTeamToBe} ${directionOfChange}</td>
                                    </tr>
                                    <tr>
                                        <th
                                            style=" font-size: 11.5px;padding:5.5px 8px;background-color: #e9e7e7;text-align: left;font-family:ShellMedium;">
                                            
                                             <a href="https://eu001-sp.shell.com/sites/AAAAA0313/HRBNP/Employee/Forms/AllItems.aspx?id=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation%2FManagement%20Level%20Compression%2Epdf&parent=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 MANAGEMENT LEVEL COMPRESSION
                                                            </a>
                                            
                                            </th>
                                        <td
                                            style="font-size: 14px;padding:5.5px 10px;background-color: ${colorCodeCompression};text-align: center;font-family:ShellMedium;">
                                            ${compressionOfTeam.toFixed(
                                              2
                                            )} </td>
                                        <td
                                            style="font-size: 14px;padding:5.5px 6px;background-color:${colorCodeCompressionTobe};text-align: center;font-family:ShellMedium;">
                                            ${compression.toFixed(
                                              2
                                            )} ${directionOfChangeForCompression}</td>
                                    </tr>
                                    <tr>
                                        <th
                                            style="font-size: 11.5px;padding:5.5px 8px;background-color: #e9e7e7;text-align: left;font-family:ShellMedium;">
                                             <a href="https://eu001-sp.shell.com/sites/AAAAA0313/HRBNP/Employee/Forms/AllItems.aspx?id=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation%2FOrganisational%20Layers%2Epdf&parent=%2Fsites%2FAAAAA0313%2FHRBNP%2FEmployee%2FLive%20Policies%2FLive%20JE%20Policy%2FEVA%2FContent%20for%20Line%20Managers%2FSection%201%20All%20About%20Job%20Evaluation"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 ORGANISATION LAYERS
                                                            </a>
                                            
                                            </th>
                                        <td
                                            style="font-size: 14px;padding:5.5px 10px;background-color: ${colorCodeMaxLayer};text-align: center;font-family:ShellMedium;">
                                            ${maxNoOfLayersOfTeam}</td>
                                        <td
                                            style="font-size: 14px;padding:5.5px 6px;background-color:${colorCodeMaxLayer};text-align: center;font-family:ShellMedium;">
                                            ${maxNoOfLayersOfTeamToBe}</td>
                                   </tr>
                                         ${
                                           sameJGReportingsInTeam !== null
                                             ? `<tr>
                                               <th style="font-size: 11.5px;padding:5.5px 10px;background-color: #e9e7e7;text-align: left;font-family:ShellMedium;">
                                                 SAME JG REPORTING
                                               </th>
                                               <td style="font-size: 14px;padding:5.5px 6px;background-color:#ff0000;text-align: center;font-family:ShellMedium;">
                                                 ${sameJGReportingsInTeam}
                                               </td>
                                                <td style="font-size: 14px;padding:5.5px 6px;background-color:${
                                                  sameJGReportingsTobe !== 0
                                                    ? "#ff0000"
                                                    : "#0ab15e"
                                                };text-align: center;font-family:ShellMedium;">
                                                 ${sameJGReportingsTobe}
                                               </td>
                                             </tr>`
                                             : ""
                                         }
                                          <tr>
                                                    <td colspan="3" style="padding:3.5px 8px;background-color: #e9e7e7;">
                                                        <p style="font-size:12px;font-weight:700;text-align:justify"">
                                                            Colours indicate alignment with (green), falling below (amber) or
                                                            significantly below (red) Shell organisation design standards.
                                                        </p>
                                                    </td>
                                                 </tr>
                                </tbody>
                            </table>
                        </td>
                                   
                    
                                    ${
                                      jobDescriptions.length !== 0
                                        ? `<td style="width:260px;padding:0;vertical-align:top;color:#153643;margin:0 5px;">
                                        <table style="padding: 0;width: 100%;margin: 0;">
                                            <thead>
                                                <th colspan="2" style="font-size: 12px;padding: 10px 0px;background-color: #FFEAC2;">
                                                    EVA ANALYSIS OF MANAGEMENT LEVEL FOR NEW/CHANGED POSITIONS
                                                </th>
                                            </thead>
                                            <tbody>${ragIndicatorTable.join(
                                              ""
                                            )}</tbody>
                                        </table>
                                    </td>`
                                        : ""
                                    }
                                
                
                        
                    </tr>
                </table>
            </td>
        </tr>


<tr>
    <td style="padding:5px 30px 5px 30px;">
        <table role="presentation" style="width:100%;">
                            <tr>
                                    ${
                                      jobDescriptions.length !== 0
                                        ? `<td style="width:260px;padding:0;vertical-align:top;color:#153643;margin:0 5px;">
                                        <table style="padding: 0;width: 100%;margin: 0;">
                                            <thead>
                                                <th style="font-size: 12px;padding: 10px 0px;background-color: #FFEAC2;">
                                                    UPLOADED JOB DESCRIPTIONS
                                                </th>
                                            </thead>
                                            <tbody>${jobDescriptionLinks.join(
                                              ""
                                            )}</tbody>
                                        </table>
                                    </td>`
                                        : ""
                                    }

                            <td style="width:260px;padding:0;vertical-align:top;color:#153643;margin:0 5px;">
                                <table style="padding: 0;width: 100%;margin: 0;">
                                   <thead>
                                    <tr>
                                        <th scope="col"
                                            style="font-size: 12px;padding: 10px 5px;background-color: #FFEAC2;">
                                            ANNUAL COST OF EMPLOYMENT</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th
                                            style="font-size: 11px;padding: 2px 15px;background-color:#e9e7e7;font-weight: 550;text-align: justify;">
                                            Please note: Total cost of employment shown is based on standard pay structure of country 
                                            selected. Actual amount may be (significantly) different due to specific pay structures for 
                                            the location, Business or Function. Furthermore, actual pay of incumbents depends on position 
                                            in the pay scale range. 
                                            </br>
                                            <span style="margin-top: 5px;display: block;">Indication of changed annual cost of employment as a result of position adjustment(s). </span>
                                            <p
                                                style="font-size: 24px;font-family:ShellMedium;font-weight: bold;margin-top:1.25px;padding:10px 0 0 0;text-align: center;">
                                                ${sign} ${coe}</p>
                                        </th>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
            </table>
      </td>
 </tr>

<tr>
    <td style="padding:5px 30px 5px 30px;">
        <table role="presentation" style="width:100%;">
                            <tr>
                                   
  <td style="width:260px;padding:0;vertical-align:top;color:#153643;margin:0 5px;">
                                      <table style="padding: 0;width: 100%;margin: 0;">
                                            <thead>
                                                <th style="font-size: 12px;padding: 10px 0px;background-color: #FFEAC2;">
                                                    FURTHER INFORMATION</th>
                                            </thead>
                                            <tbody>
                                           
                                        
                                            <tr>
                                            
                                                    <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                                                        <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        <a href="https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Introduction/Setting%20Management%20Levels%20at%20Shell%20v.%20March%202022.pdf?csf=1&web=1&e=4T36Yc"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 Setting Management Levels at Shell
                                                            </a>
                                                        </p>
                                                    </th>
                                            </tr>
                                            
                                            <tr>
                                            <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                                                <p style="margin:0;font-size:12.75px;text-align:justify">
                                                <a href="https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/Management%20Level%20indication%20March%202022.pdf?csf=1&web=1&e=m5gaJM"
                                                        style="text-decoration:none;color:#003c88;">
                                                        🔗 Management Level Probability Analysis
                                                    </a>
                                                </p>
                                            </th>
                                             </tr>
                                             <tr>
                                                
                                                    <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                                                        <p style="margin:0;font-size:12.75px;text-align:justify">
                                                            <a href="https://eu001-sp.shell.com/:b:/r/sites/AAAAA0313/HRBNP/Employee/Live%20Policies/Live%20JE%20Policy/EVA/Content%20for%20Line%20Managers/Section%203%20How%20Do%20I%20Set%20the%20Grade%20for%20JG5%20-%20JG3/EVA%20User%20Guide%20for%20Line%20Managers%20v.March%202022.pdf?csf=1&web=1&e=GkYPSI"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 EVA User Guide (Full)
                                                            </a>
                                                        </p>
                                                    </th>
                                                </tr>
                                                <tr>
                                                
                                                <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                                                    <p style="margin:0;font-size:12.75px;text-align:justify">
                                                        <a href="https://shellhronline.force.com/HROnlineEC/s/article/Job-Evaluation-for-Line-Managers"
                                                            style="text-decoration:none;color:#003c88;">
                                                            🔗 Job Evaluation Website 
                                                        </a>
                                                    </p>
                                                </th>
                                            </tr>
                                            <tr>
                                            <th style="font-size: 11px;padding: 5px 8px;background-color: #e9e7e7;text-align: left">
                                                        <p style="margin:0;font-size:12.75px;text-align:justify">
                                                            <a href="https://eu001-sp.shell.com/sites/AAAAB5282/Public/Pages/en_GL/Content/Tier0/Global/sc_contact.aspx?RoleType=Home&CPTitle=HR%20Services%20Contacts"
                                                                style="text-decoration:none;color:#003c88;">
                                                                🔗 HR Advice Representative
                                                            </a>
                                                        </p>
                                                    </th>
                                                </tr>
                                            </tbody>
            </table>
        </td>
               
                    </tr>
            </table>
      </td>
 </tr>






        <tr>
            <td style="padding:5px 30px 5px 30px;">
                <table role="presentation" style="width:100%;border-collapse:collapse;border:0;border-spacing:0;">
                    <tr>
                     <td style="padding:0 0 8px 0;color:#153643;">
                        <h1 style="font-size: 14px; margin: 0 0 5px 5px;color: #000000; font-weight: 600;">ACTIONS REQUIRED FROM THE REQUESTOR</h1>
                        <table style="padding: 0;width: 100%;margin: 0 5px;">
                            <tbody>
                                
                                </tbody>
                                ${amberoutCome}
                                ${emailNextStepsForOverallOutcome.join("")}
                            
                        </table>
                     </td>
                    </tr>
                </table>
            </td>
        </tr>
 
        <tr>
            <td style="padding:25px;background:#fbce07;">
                <table role="presentation"
                    style="width:100%;border-collapse:collapse;border:0;border-spacing:0;font-size:9px;">
                    <tr>
                        <td style="padding:0;width:50%;" align="left">
                            <p style="margin:0 0 0 15px;font-size:13px;font-weight:bold;line-height:16px;color:#00000;">
                                Ⓒ Copyright of Shell International B.V. <br />
                            </p>
                        </td>
                    </tr>
                </table>
            </td>
        </tr>
    </table>
    </td>
    </tr>
    </table>
</body>
</html>`;
};
