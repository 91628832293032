import Button from "../../components/button/Button";
import Logo from "../../assets/logo_small.png";

const AuthLogout = (props) => {
  return (
    <div className="auth-container">
      <img src={Logo} alt="logo" className="login-logo" />

      <span className="auth-title">You have now been logged out.</span>
      <span className="auth-text">
        Thank you for using <span style={{ color: "#dd1d21" }}>EVA</span> -{" "}
        <span style={{ fontStyle: "italic" }}>
          Your guide to prepare Management Levels for new and changed positions​
        </span>
      </span>
      <div className="auth-button-container">
        <Button onClick={props.login} size="medium">
          LOGIN
        </Button>
      </div>
    </div>
  );
};

export default AuthLogout;
