import React from "react";
import LinkIcon from "../../icons/link/LinkIcon";

const WelcomePageLink = (props) => {
  return (
    <div className="link-to">
      <span className="icon-container">
        <LinkIcon />
      </span>
      <span className="link-text">{props.children}</span>
    </div>
  );
};

export default WelcomePageLink;
