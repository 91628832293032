import React from "react";
import Logo from './../logo/Logo';
import warning from "../../assets/warningIcon.png";
import close from "../../assets/closeIcon.png";
import success from "../../assets/successIcon.png";


const aiHeader = (props) => {
  const {
    managementLevel,
    aiManagementLevel,
    selectedManagementLevel,
    jobProfile,
    isDuplicated
  } = props;
  // console.log("isduplicated", props.isDuplicated);

  if (
    (managementLevel !== aiManagementLevel &&
      managementLevel !== selectedManagementLevel) ||
    selectedManagementLevel === aiManagementLevel && (!isDuplicated)
  ) {
    return null;
  }

  let sign = null;
  let text = null;
  if (
    selectedManagementLevel !== aiManagementLevel &&
    selectedManagementLevel === managementLevel && (!isDuplicated)
  ) {
    sign = close;
    text = (
      <p className={`description-popup-text`}>
        EVA ANALYSIS SUGGESTS A LOWER LEVEL THAN THIS LEVEL YOU SELECTED.
      </p>
    );
  }
  //for duplicateed positions
 if(isDuplicated && selectedManagementLevel === managementLevel){
   sign = warning;
  text = (
    <p className={`description-popup-text`}>AS YOU ARE DUPLICATING A POSITION, 
    YOU CAN NOT CHANGE YOUR SELECTION. GO BACK TO YOUR TEAM OVERVIEW IF YOU NEED TO SET 
    UP A NEW POSITION INSTEAD.


    </p>
  );
 }
  if (
    selectedManagementLevel !== managementLevel &&
    aiManagementLevel === managementLevel && (!isDuplicated)
  ) {
    sign = success;
      
    text = (
      <React.Fragment>
        <p className={`description-popup-text`}>
          THIS IS THE MANAGEMENT LEVEL EVA ANALYSIS SUGGESTS.
        </p>
        <br></br>
        {!jobProfile.includes("00000000") ? null : (
          <p className={`description-popup-text`}>
            This Management Level does not exist for this Job Profile. ​The job
            profile shown serves as an example of expected dimensions at this
            level. You can select this generic job profile or go back and
            reselect an appropriate job profile.
          </p>
        )}
      </React.Fragment>
    );
  }
  return (
    <div
      className={`description-popup ${
        jobProfile.includes("00000000") ? "extra-popup" : ""
      }`}
    >
      <div className="description-popup-logo-container"><img src ={sign}
     className="description-logo"
     height="511pt"
     width="511pt"/></div>
      {jobProfile.includes("00000000") ? (
        <div style={{ marginTop: "10px" }}>{text}</div>
      ) : (
        text
      )}
    </div>
  );
};

export default aiHeader;
