import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../components/button/Button";
import Location from "../../components/icons/location/Location";
import ToolTip from "../tooltip/ToolTip";
import Swal from "sweetalert2";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import { selectAuth } from "../../app/features/authSlice";
import info from "../../assets/infoIcon.png";
import warning from "../../assets/warningIcon.png";
import success from "../../assets/successIcon.png";
import question from "../../assets/questionIcon.png";

const ManagerPosition = (props) => {
  const history = useHistory();

  const {
    isChanged,
    isNewPosition,
    isDuplicated,
    positionTitle,
    positionId,
    jobProfile,
    location,
    isUnfilled,
    isEditAndDeleteAllowed,
    spanOfControl,
    compression,
    sameJobReportings,
    maxNoOfLayers,
    isActionNotAllowed,
  } = props;

  const { noOfChanges } = useSelector(selectLineManager);
  const { adminParameters } = useSelector(selectAuth);

  const {
    compressionGREENGreaterAndEqualTo,
    compressionGREENLessAndEqualTo,
    compressionORANGEUPPERGreaterAndEqualTo,
    compressionORANGEUPPERLessAndEqualTo,
    compressionREDGreaterAndEqualTo,
    compressionREDLessAndEqualTo,
    compressionORANGELOWERGreaterAndEqualTo,
    compressionORANGELOWERLessAndEqualTo,
    layersGREENGreaterAndEqualTo,
    layersGREENLessAndEqualTo,
    layersORANGEGreaterAndEqualTo,
    layersORANGELessAndEqualTo,
    spanOfControlGREENGreaterAndEqualTo,
    spanOfControlGREENLessAndEqualTo,
    spanOfControlORANGEGreaterAndEqualTo,
    spanOfControlORANGELessAndEqualTo,
    spanOfControlREDGreaterAndEqualTo,
    spanOfControlREDLessAndEqualTo,
  } = adminParameters;

  let colorCodeSpanControl = null;
  if (
    spanOfControl <= spanOfControlREDLessAndEqualTo &&
    spanOfControl >= spanOfControlREDGreaterAndEqualTo
  ) {
    colorCodeSpanControl = "red";
  } else if (
    spanOfControl <= spanOfControlORANGELessAndEqualTo &&
    spanOfControl >= spanOfControlORANGEGreaterAndEqualTo
  ) {
    colorCodeSpanControl = "amber";
  } else if (
    spanOfControl >= spanOfControlGREENGreaterAndEqualTo &&
    spanOfControl <= spanOfControlGREENLessAndEqualTo
  ) {
    colorCodeSpanControl = "green";
  }

  let colorCodeCompression = null;
  if (
    compression >= compressionREDGreaterAndEqualTo &&
    compression <= compressionREDLessAndEqualTo
  ) {
    colorCodeCompression = "red";
  } else if (
    (compression >= compressionORANGELOWERGreaterAndEqualTo &&
      compression <= compressionORANGELOWERLessAndEqualTo) ||
    (compression > compressionORANGEUPPERGreaterAndEqualTo &&
      compression <= compressionORANGEUPPERLessAndEqualTo)
  ) {
    colorCodeCompression = "amber";
  } else if (
    compression >= compressionGREENGreaterAndEqualTo &&
    compression <= compressionGREENLessAndEqualTo
  ) {
    colorCodeCompression = "green";
  }

  let colorCodeMaxLayer = null;
  if (
    maxNoOfLayers >= layersGREENGreaterAndEqualTo &&
    maxNoOfLayers <= layersGREENLessAndEqualTo
  ) {
    colorCodeMaxLayer = "green";
  } else if (
    maxNoOfLayers >= layersORANGEGreaterAndEqualTo &&
    maxNoOfLayers <= layersORANGELessAndEqualTo
  ) {
    colorCodeMaxLayer = "amber";
  }

  let sameJobReportingText = null;
  if (sameJobReportings !== null) {
    sameJobReportingText = (
      <div className="factor">
        <span className="factor-title">Same Management Level reporting</span>
        <span
          className={`factor-value ${
            sameJobReportings !== null ? "red" : "green"
          }`}
        >
          {sameJobReportings}
        </span>
      </div>
    );
  }

  const getJobIdWithProfile = (jobProfile) => {
    if (!jobProfile) return [];
    var i = jobProfile.indexOf("-");
    if (i > 0) {
      return [jobProfile.substring(0, i), jobProfile.substring(i + 1)];
    } else return [jobProfile];
  };

  const onChangeHandler = () => {
    const customButtonClasses = Swal.mixin({
      customClass: {
        confirmButton: "change-button confirm",
        cancelButton: "change-button cancel",
      },
      buttonsStyling: false,
    });
    if (noOfChanges >= 3) {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    } else {
      customButtonClasses
        .fire({
          html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">When editing this position ensure no same job group reporting is created with any direct reports of this position.</p>`,
          imageUrl:info,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "info",
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (noOfChanges < 3) {
              history.push(`/home/change/${positionId}`);
            }
          }
        });
    }
  };

  const onRemoveHandler = () => {
    if (noOfChanges >= 3) {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl:warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    } else {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">This position can not be removed as that would impact the reporting lines of positions underneath. Reach out to HR Advice to discuss.</p>`,
        imageUrl: warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }
  };

  const onDuplicateHandler = () => {
    if (noOfChanges < 3) {
      const customButtonClasses = Swal.mixin({
        customClass: {
          confirmButton: "change-button confirm",
          cancelButton: "change-button cancel",
        },
        buttonsStyling: false,
      });
      customButtonClasses
        .fire({
          html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">When duplicating any position only change in location is allowed.</p>`,
          imageUrl:info,
          imageWidth: 75,
          imageHeight: 75,
          imageAlt: "info",
          showCancelButton: true,
          confirmButtonText: "Proceed",
          cancelButtonText: "Cancel",
        })
        .then((result) => {
          if (result.isConfirmed) {
            if (noOfChanges < 3) {
              history.push(`/home/duplicate/${positionId}`);
            }
          }
        });
    } else {
      Swal.fire({
        html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">Maximum of 3 changes can be triggered, if more please consult HR advice to get appropriate support.</p>`,
        imageUrl:warning,
        imageWidth: 75,
        imageHeight: 75,
        imageAlt: "warning",
        confirmButtonText: "Okay",
        buttonsStyling: false,
        customClass: {
          confirmButton: "swal-button-manager-remove",
        },
      });
    }
  };

  let blurred = "";
  if (isActionNotAllowed) {
    blurred = "card-blur";
  }

  return (
    <div className="manager-position">
      {isUnfilled ? (
        <span className={`unfilled-tag for-manager ${blurred}`}>Unfilled</span>
      ) : null}
      {isActionNotAllowed && (
        <h1 className="changed-position-tag">POSITION CHANGED</h1>
      )}
      <div className={`manager-details ${blurred}`}>
        <p className="manager-text util-1">{positionTitle} </p>
        <p className="manager-text">Position number : {positionId}</p>
        <p className="manager-text">
          Job Profile : {getJobIdWithProfile(jobProfile)[0]} -
        </p>
        <p className="manager-text align-center">
          {getJobIdWithProfile(jobProfile)[1]}
        </p>
        <div className="location-box-manager">
          <Location />
          <p className="location-text">{location}</p>
        </div>
      </div>
      {!isNewPosition && !isChanged && !isDuplicated ? (
        <div className={`osh-report ${blurred}`}>
          <div className="factor">
            <span className="factor-title">Span of Control </span>
            <span className={`factor-value ${colorCodeSpanControl}`}>
              {spanOfControl ? spanOfControl : "N/A"}
            </span>
          </div>
          <div className="factor">
            <span className="factor-title">Management Level COMPRESSION </span>
            <span
              className={`factor-value ${
                compression !== null ? colorCodeCompression : ""
              }`}
            >
              {compression !== null ? compression.toFixed(2) : "N/A"}
            </span>
          </div>
          <div className="factor">
            <span className="factor-title">
              Organisation Layer of Direct reports{" "}
            </span>
            <span className={`factor-value ${colorCodeMaxLayer}`}>
              {maxNoOfLayers}
            </span>
          </div>
          {sameJobReportingText}
        </div>
      ) : null}
      <div className="card-buttons-manager">
        {!isEditAndDeleteAllowed ? (
          <ToolTip
            message="This position is not within ML 3-5 range and can therefore only be removed"
            side="top"
          >
            <span>
              <Button
                disabled={!isEditAndDeleteAllowed || isActionNotAllowed}
                primary
                size="small"
                onClick={onChangeHandler}
              >
                CHANGE
              </Button>
            </span>
          </ToolTip>
        ) : (
          <Button
            disabled={!isEditAndDeleteAllowed || isActionNotAllowed}
            primary
            size="small"
            onClick={onChangeHandler}
          >
            CHANGE
          </Button>
        )}

        <Button
          onClick={onRemoveHandler}
          disabled={isActionNotAllowed}
          danger
          size="small"
        >
          REMOVE
        </Button>
        {!isEditAndDeleteAllowed ? (
          <ToolTip
            message="This position is not within ML 3-5 range and can therefore only be removed"
            side="top-right"
          >
            <span>
              <Button
                duplicate
                size="small"
                disabled={!isEditAndDeleteAllowed || isActionNotAllowed}
                onClick={onDuplicateHandler}
              >
                DUPLICATE
              </Button>
            </span>
          </ToolTip>
        ) : (
          <Button
            duplicate
            size="small"
            disabled={!isEditAndDeleteAllowed || isActionNotAllowed}
            onClick={onDuplicateHandler}
          >
            DUPLICATE
          </Button>
        )}
      </div>
    </div>
  );
};

export default ManagerPosition;
