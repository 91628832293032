import React from "react";
import { useSelector } from "react-redux";
import { selectLineManager } from "../../app/features/lineManagerSlice";
import Location from "../../components/icons/location/Location";

const LineManager = (props) => {
  const { positionId, positionTitle, location, jobProfile } = props;
  const { noOfChanges } = useSelector(selectLineManager);

  const getJobIdWithProfile = (jobProfile) => {
    if (!jobProfile) return [];
    var i = jobProfile.indexOf("-");
    if (i > 0) {
      return [jobProfile.substring(0, i), jobProfile.substring(i + 1)];
    } else return [jobProfile];
  };

  return (
    <div className="manager-container">
      <div className="legends">
        <div className="legend ">
          <span className="legend-box manager"></span>
          <span className="legend-title">- Managerial position</span>
        </div>
        <div className="legend ">
          <span className="legend-box individual"></span>
          <span className="legend-title">- Individual Contributor</span>
        </div>
      </div>

      <div className="line-manager-card">
        <div className="line-manager-details">
          <p className="line-manager-text util-1">{positionTitle}</p>
          <p className="line-manager-text">Position number : {positionId}</p>
          <p className="line-manager-text">
            Job Profile : {getJobIdWithProfile(jobProfile)[0]} -
          </p>
          <p className="line-manager-text font-align-center-lm">
            {getJobIdWithProfile(jobProfile)[1]}
          </p>
          <div className="location-box-linemanager">
            <Location />
            <p className="outcome-card-text">{location}</p>
          </div>
        </div>
      </div>
      {noOfChanges !== 0 ? (
        <div className="legends-positions">
          <div className="legend ">
            <span className="legend-box removed-position-border"></span>
            <span className="legend-title">- Removed position</span>
          </div>
          <div className="legend ">
            <span className="legend-box new-position-border"></span>
            <span className="legend-title">- new position</span>
          </div>
          <div className="legend ">
            <span className="legend-box duplicated-position-border"></span>
            <span className="legend-title">- Duplicated Position</span>
          </div>
          <div className="legend ">
            <span className="legend-box changed-position-border"></span>
            <span className="legend-title">- Changed Position</span>
          </div>
        </div>
      ) : (
        <p className="legend-text">
          Position data of your AS - IS team originates from Workday. It is
          refreshed every Monday at 12:30 pm UTC.
        </p>
      )}
    </div>
  );
};

export default LineManager;
