import React from "react";
import { Link } from "react-router-dom";
import LinkIcon from "../../components/icons/link/LinkIcon";
import VideoLinkIcon from "../../components/icons/link/videoLinkIcon";
import DocumentLinkIcon from "../../components/icons/link/documentLinkIcon";
import {
  spanOfControlLink,
  managementLevelCompression,
  organisationLayers,
  evaUserGuide,
  hrAdviceRepresentative,
  evaUserGuideShort,
  systemSupportLink,
  managementLevelProbabilityAnalysis,
} from "../../utils/utils";

const OshLinks = (props) => {
  return (
    <div className="links-osh">
      <h4 className="links-header">FURTHER INFORMATION</h4>
      <div className="links-list-osh">
        <Link
          className="osh-link"
          to={{
            pathname: spanOfControlLink,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
              <span className="icon-wrapper">
                <VideoLinkIcon />
              </span>
             <span>SPAN OF CONTROL</span>
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: managementLevelCompression,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
              <span className="icon-wrapper">
                <VideoLinkIcon />
              </span>
             <span>MANAGEMENT LEVEL COMPRESSION</span>
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: organisationLayers,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
          <span className="icon-wrapper">
                <VideoLinkIcon />
              </span>
              <span> ORGANISATION LAYERS </span>
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: managementLevelProbabilityAnalysis,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
            {" "}
            <span className="icon-wrapper">
                <DocumentLinkIcon />
              </span>
              <span>MANAGEMENT LEVEL PROBABILITY ANALYSIS</span>
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: evaUserGuideShort,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
            <span className="icon-wrapper">
              <DocumentLinkIcon />
            </span>
            EVA USER GUIDE (SHORT)
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: evaUserGuide,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
            <span className="icon-wrapper">
              <DocumentLinkIcon />
            </span>
            EVA USER GUIDE (FULL)
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: hrAdviceRepresentative,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
            <span className="icon-wrapper">
              <LinkIcon />
            </span>
            HR ADVICE REPRESENTATIVE
          </div>
        </Link>
        <Link
          className="osh-link"
          to={{
            pathname: systemSupportLink,
          }}
          target="_blank"
        >
          <div className="link-osh-one">
            <span className="icon-wrapper">
              <LinkIcon />
            </span>
            HR SYSTEMS SUPPORT
          </div>
        </Link>
      </div>
    </div>
  );
};

export default OshLinks;
