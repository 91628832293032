import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import Select from "../../components/select/Select";
import Button from "../../components/button/Button";
import {
  getObject,
  createOptions,
  getObjectCapitalize,
} from "../../utils/utils";
import {
  selectLineManager,
  onShowLink,
  onHideLink,
  onUpdatedJdText,
} from "../../app/features/lineManagerSlice";
import DescriptionPage from "../description-page/DescriptionPage";
import ToolTip from "../tooltip/ToolTip";
import { selectAuth } from "../../app/features/authSlice";
import Upload from "../../components/upload/Upload";
import { jdTextLabel } from "../outcome-sheet-texts/OutcomeSheetTexts";

import ReactDOMServer from "react-dom/server";
import Swal from "sweetalert2";

import axios from "axios";
import https from "https";

import LoadingOverlay from "react-loading-overlay";
import warning from "../../assets/warningIcon.png";
import info from "../../assets/infoIcon.png";
import success from "../../assets/successIcon.png";
import question from "../../assets/questionIcon.png";

const FormContainer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [isFocused, setIsFocused] = useState(false);
  const { teamDetails, jobDescriptionFile } = useSelector(selectLineManager);
  const { managementLevelOfLineManger, maxNoOfLayersOfTeam } =
    useSelector(selectAuth);
  const [jdLink, setJdLink] = useState(null);

  const [jobTitle, setJobTitle] = useState(
    props.jobTitle ? props.jobTitle : ""
  );
  const [jobFamilyGroup, setJobFamilyGroup] = useState(
    props.jobFamilyGroup
      ? {
          value: props.jobFamilyGroup.toUpperCase(),
          label: props.jobFamilyGroup.toUpperCase(),
        }
      : ""
  );
  const [jobFamily, setJobFamily] = useState(
    props.jobFamily
      ? {
          value: props.jobFamily.toUpperCase(),
          label: props.jobFamily.toUpperCase(),
        }
      : ""
  );
  const [jobProfileWithId, setJobProfileWithId] = useState(
    props.jobProfileWithId
      ? {
          value: props.jobProfileWithId,
          label: props.jobProfileWithId.toUpperCase(),
        }
      : ""
  );
  const [managementLevel, setManagementLevel] = useState(
    props.managementLevel ? props.managementLevel : ""
  );
  const [location, setLocation] = useState(
    props.location ? { value: props.location, label: props.location } : ""
  );

  const [jobFamilyId, setJobFamilyId] = useState(
    props.jobFamily ? props.jobFamily.toUpperCase() : ""
  );
  const [jobFamilyGroupId, setJobFamilyGroupId] = useState(
    props.jobFamilyGroup ? props.jobFamilyGroup.toUpperCase() : ""
  );

  const [jobFamilyGroupOptions, setJobFamilyGroupOptions] = useState([]);
  const [jobFamilyOptions, setJobFamilyOptions] = useState([]);
  const [jobProfileInTeamOptions, setJobProfileInTeamOptions] = useState([]);
  const [jobProfileWithIdOptions, setJobProfileWithIdOptions] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);

  const [error, setError] = useState(null);

  const [showError, setShowError] = useState(false);
  const [jobTitleError, setJobTitleError] = useState("");
  const [showFormError, setShowFormError] = useState(false);
  const [locationError, setLocationError] = useState(false);
  const [showJdUploadError, setShowJdUploadError] = useState(false);

  const [comments, setComments] = useState("");
  const [textAreaError, setTextAreaError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState({});
  const [fileUploadError, setFileUploadError] = useState("");
  const [jdUploadError, setJdUploadError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [aiManagementLevel, setAiManagementLevel] = useState(null);
  const [confidence, setConfidence] = useState(null);
  const [jdParsedTextAi, setJdParsedTextAi] = useState(null);
  const [aiResponse, setAiResponse] = useState(null);

  const jobProfilesInTheTeam = [];
  teamDetails.map((value) => {
    jobProfilesInTheTeam.push({
      jobFamilyGroup: value.jobFamilyGroup
        ? value.jobFamilyGroup.toUpperCase()
        : "",
      jobFamily: value.jobFamily ? value.jobFamily.toUpperCase() : "",
      jobProfile: value.jobProfile ? value.jobProfile : "",
      managementLevel:
        value.ManagementLevel !== null ? value.ManagementLevel : "",
    });
    return [...new Set(jobProfilesInTheTeam)];
  });

  const filteredTeam = jobProfilesInTheTeam.filter(
    (v, i) => !v.jobProfile.includes("CLA")
  );

  const jobProfileArray = [...new Set(filteredTeam.map((e) => e.jobProfile))];

  const jobProfilesInTheTeamOptions = getObjectCapitalize(jobProfileArray);

  useEffect(() => {
    const fetchFamilyGroup = async () => {
      try {
        const jobFamilyGroup = await fetch(
          `${process.env.REACT_APP_API_URL}/familygroup`
        );
        const locations = await fetch(
          `${process.env.REACT_APP_API_URL}/locations`
        );
        const result1 = await jobFamilyGroup.json();
        const result2 = await locations.json();

        const data1 = getObject(result1);
        const data2 = createOptions(result2);
        setJobFamilyGroupOptions(data1);
        setLocationOptions(data2);
        setJobProfileInTeamOptions(jobProfilesInTheTeamOptions);
      } catch (error) {
        setError("Something went wrong");
      }
    };
    fetchFamilyGroup();
    return () => {
      setJobFamilyGroupOptions([]);
      setLocationOptions([]);
      setJobProfileInTeamOptions([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchJobFamilies = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/jobfamily?familyGroupId=${jobFamilyGroupId}`
        );
        const result = await response.json();
        const data = getObject(result);
        setJobFamilyOptions(data);
      } catch (error) {
        setError("something went wrong");
      }
    };
    fetchJobFamilies();
  }, [jobFamilyGroupId]);

  useEffect(() => {
    const getJobProfilesWithId = async () => {
      try {
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ jobFamilyId }),
        };
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/jobProfile`,
          options
        );
        const result = await response.json();

        const updatedResult = result.filter((res) => {
          // console.log("managementlevels", res.split("")[res.length-1]);
          return res !== undefined && res && !res.includes(`00000000`) && res.split("")[res.length-1] < 6;//add filtering for jg6
        });

        const data = getObjectCapitalize(updatedResult);
        setJobProfileWithIdOptions(data);
      } catch (error) {
        setError("something went wrong");
      }
    };
    getJobProfilesWithId();
  }, [jobFamilyId]);

  const aiPopUpIcon = (response) => {
    const aiManagementLevel = parseInt(
      response.AI_EVA_Retraining_JG.split("")[2]
    );
    const confidence = response.confidence;

    if (response === undefined) {
      return;
    }
    let aiIcon = "warning";
    if (
      (confidence === "high" || confidence === "medium") &&
      aiManagementLevel > managementLevel
    ) {
      aiIcon = "warning";
    } else if (
      //pop up 3
      (confidence === "high" || confidence === "medium") &&
      aiManagementLevel === managementLevel
    ) {
      aiIcon = "success";
    } else if (
      // pop up 4
      (confidence === "high" || confidence === "medium") &&
      aiManagementLevel < managementLevel
    ) {
      aiIcon = "warning";
    } else if (confidence === "low") {
      //pop up 2

      aiIcon = "question";
    }
    return aiIcon;
  };

  const aiMessage = (response) => {
    if (response === undefined) {
      return;
    }

    const aiManagementLevel = parseInt(
      response.AI_EVA_Retraining_JG.split("")[2]
    );
    const confidence = response.confidence;
    const largestOfResult = Math.max.apply(null, response.result[0]);

    let aiReviewMessage;
    let aiFinalMessage;
    let aiAnalysisMessage;
    let aiIcon = warning;
    // pop up 1
    if (
      (confidence === "high" || confidence === "medium") &&
      aiManagementLevel > managementLevel
    ) {
      aiReviewMessage =
        "EVA analysis suggests a lower level than the one you have selected.";
      aiAnalysisMessage =
        "​The analysis shows elements of the following Management Levels:";
      aiFinalMessage = "Review the Job Profiles and validate your selection.";
      aiIcon = warning;
    } else if (
      //pop up 3
      (confidence === "high" || confidence === "medium") &&
      aiManagementLevel === managementLevel
    ) {
      aiReviewMessage =
        "EVA analysis suggests the same Management Level you selected.";
      aiAnalysisMessage = "";
      aiFinalMessage =
        "While you seem good to go, please review the job profiles in the next screen to finalise your selection.";
      aiIcon = success;
    } else if (
      // pop up 4
      (confidence === "high") &&
      aiManagementLevel < managementLevel
    ) {
      aiReviewMessage =
        "EVA analysis suggests that the responsibilities of the position are heavy in comparison to the Management Level you selected.";
      aiAnalysisMessage = "";
      aiFinalMessage = "​Please carefully review the Job Profiles and confirm  your original choice.";
      aiIcon = warning;
    } else if ((confidence === "low") || ((confidence === "medium") &&
    aiManagementLevel < managementLevel)) {
      //pop up 2
      aiReviewMessage =
        "EVA analysis was unable to identify a clear Management Level with enough confidence.​";
      aiAnalysisMessage =
        "Typically this happens when the analysis shows elements at a variety of Management Levels with none of them clearly standing out.​";
      aiFinalMessage =
        "Please review the Job Profiles and select the best match.​";
      aiIcon = question;
    }

    return ReactDOMServer.renderToStaticMarkup(
      <div className="ai-popup">
        <div className="ai-icon-border">
          <img src = {aiIcon} alt='icon' className="ai-icon"/>
        </div>
        <h4 className="ai-header">{aiReviewMessage}</h4>
        <h4 className="ai-title">{aiAnalysisMessage}</h4>
        {confidence === "low" ||
        ((confidence === "high" || confidence === "medium") &&
          aiManagementLevel < managementLevel) ? null : (
          <React.Fragment>
            <h3 className="ai-title-pos">
              Probability of Management levels ​for {jobTitle}
            </h3>
            <div className="bars">
              <div className="bar-container">
                <div
                  className="bar"
                  style={{
                    height: `${
                      parseFloat(100 * response.result[0][0].toFixed(5)) >= 5
                        ? 150 * response.result[0][0].toFixed(5)
                        : 0
                    }px`,
                    backgroundColor:
                      largestOfResult.toFixed(5) ===
                      response.result[0][0].toFixed(5)
                        ? "#fbce07"
                        : "#86888a",
                  }}
                ></div>
                <h4 className="bar-title">ML3</h4>
              </div>
              <div className="bar-container">
                <div
                  className="bar"
                  style={{
                    height: `${
                      parseFloat(100 * response.result[0][1].toFixed(5)) >= 5
                        ? 150 * response.result[0][1].toFixed(5)
                        : 0
                    }px`,
                    backgroundColor:
                      largestOfResult.toFixed(5) ===
                      response.result[0][1].toFixed(5)
                        ? "#fbce07"
                        : "#86888a",
                  }}
                ></div>
                <h4 className="bar-title">ML4</h4>
              </div>
              <div className="bar-container">
                <div
                  className="bar"
                  style={{
                    height: `${
                      parseFloat(100 * response.result[0][2].toFixed(5)) >= 5
                        ? 150 * response.result[0][2].toFixed(5)
                        : 0
                    }px`,
                    backgroundColor:
                      largestOfResult.toFixed(5) ===
                      response.result[0][2].toFixed(5)
                        ? "#fbce07"
                        : "#86888a",
                  }}
                ></div>
                <h4 className="bar-title">ML5</h4>
              </div>

              <div className="bar-container">
                <div
                  className="bar"
                  style={{
                    height: `${
                      parseFloat(100 * response.result[0][3].toFixed(5)) >= 5
                        ? 150 * response.result[0][3].toFixed(5)
                        : 0
                    }px`,
                    backgroundColor:
                      largestOfResult.toFixed(5) ===
                      response.result[0][3].toFixed(5)
                        ? "#fbce07"
                        : "#86888a",
                  }}
                ></div>
                <h4 className="bar-title">ML6</h4>
              </div>
            </div>
          </React.Fragment>
        )}
        <h4 className="ai-title black">{aiFinalMessage}</h4>
      </div>
    );
  };

  const errorMessage = (responseCode, error) => {
    // console.log("response-code", responseCode);
    let flag = 0;
    let text = `Please ensure the attached Job Description is labelled as Internal or Confidential (Anyone/Unencrypted), so that it can be successfully processed via the tool. Please refer to the instructions how to label a document `;
    if (responseCode === -2) {
      text = "Please expand and reload.";
      flag = 1;
    } else if (responseCode === -7) {
      text = "Please check and reload.";
      flag = 1;
    }
    return ReactDOMServer.renderToStaticMarkup(
      <div className="ai-popup">
      
          <div className="ai-icon-border">
            <img src={warning} alt="icon" className="ai-icon" />
          </div>
   
        <h4
          className="ai-header"
          style={{ textAlign: "center", fontSize: "20px" }}
        >
          {error}
        </h4>
        <h4
          className="ai-header"
          style={{ textAlign: "center", fontSize: "20px" }}
        ><span>{text}<span>
        {(flag === 0)? (
            <a href="https://eu001-	sp.shell.com/sites/SPO000113/SitePages/Microsoft-Information-Protection-(MIP).aspx" target="_blank" style={{textDecoration:"underline"}}>here</a>
            ):null}  </span></span>
          </h4>
      </div>
    );
  };

  const nextStep = async (e) => {
    e.preventDefault();
    if (
      !validateJobTitle(jobTitle) ||
      jobTitle.length < 4 ||
      managementLevel < 3 ||
      managementLevel > 5 ||
      managementLevel === managementLevelOfLineManger ||
      managementLevel < managementLevelOfLineManger ||
      (!validateJobTitle(comments) &&
        (props.isPositionEdited ||
          props.isNewPosition ||
          props.isDuplicatedPosition)) ||
      (comments.trim().length < 10 &&
        (props.isPositionEdited ||
          props.isNewPosition ||
          props.isDuplicatedPosition)) ||
      (comments.trim().length > 250 &&
        (props.isPositionEdited ||
          props.isNewPosition ||
          props.isDuplicatedPosition))
    ) {
      setShowError(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else if (!jobProfileWithId || jobProfileWithId === "") {
      setShowFormError(true);
    } else if (!location || location === "") {
      setLocationError(true);
    } else if (
      (!jdLink && props.isNewPosition) ||
      (!jdLink && props.isPositionEdited)
    ) {
      setShowJdUploadError(true);
    } else if (
      jobTitleError.length === 0 &&
      step === 1 &&
      (props.isNewPosition || props.isPositionEdited)
    ) {
      setIsLoading(true);
      const data = {
        jobDescriptionText: jobDescriptionFile,
        jgOfLineManager:
          process.env.NODE_ENV === "development"
            ? 4
            : String(managementLevelOfLineManger),
        jobTitle: jobTitle,
        orgLayer:
          process.env.NODE_ENV === "development"
            ? 8
            : String(maxNoOfLayersOfTeam),
        skillpoolGroup: jobFamilyGroup.value.toLowerCase(),
      };

      // console.log("ai-data", data);

      const config = {
        method: "post",
        url:
          process.env.NODE_ENV === "development"
            ? `https://backend-node-eva-uat.azurewebsites.net/api/send-to-ai`
            : `${process.env.REACT_APP_API_URL}/send-to-ai`,
        headers: {
          "Content-Type": "application/json",
        },
        data,
      };
      const agent = new https.Agent({
        rejectUnauthorized: false,
      });
      try {
        const response = await axios(config, agent);
        // console.log("ai-response", response);

        setAiResponse(response.data);

        setIsLoading(false);
        let buttonText = "Continue to Job Profiles";
        if (
          (response.data.data.confidence === "high" ||
            response.data.data.confidence === "medium") &&
          response.data.data.AI_EVA_Retraining_JG &&
          parseInt(response.data.data.AI_EVA_Retraining_JG.split("")[2]) ===
            managementLevel
        ) {
          buttonText = "Continue to Job Profiles";
        } else if (response.data.data.response_code !== 1) {
          buttonText = "Go Back";
        }
        const customButtonClasses = Swal.mixin({
          customClass: {
            confirmButton: "change-button warning",
            container: "swal-wide",
          },
          buttonsStyling: false,
        });
        customButtonClasses
          .fire({
            html:
              response.data.data.response_code === 1
                ? aiMessage(response.data.data)
                : errorMessage(
                    response.data.data.response_code,
                    response.data.data.error
                  ),
            confirmButtonText: buttonText,
            cancelButtonText: "Cancel",
            allowOutsideClick: false,
            width: "750px",
            // icon:
            //   response.data.data.response_code === 1
            //     ? aiPopUpIcon(response.data.data)
            //     : "warning",
          })
          .then((result) => {
            if (result.isConfirmed && response.data.data.response_code === 1) {
              setStep(step + 1);
              dispatch(onShowLink());
              setAiManagementLevel(
                parseInt(response.data.data.AI_EVA_Retraining_JG.split("")[2])
              );
              setConfidence(response.data.data.confidence);
              setJdParsedTextAi(response.data.data.JD_Parsed_For_AI);
            }
          });
      } catch (error) {
        setIsLoading(false);
      }
    } else if (jobTitleError.length === 0 && step === 2) {
      setStep(step + 1);
    } else if (
      jobTitleError.length === 0 &&
      step === 1 &&
      props.isDuplicatedPosition
    ) {
      setStep(step + 1);
      dispatch(onShowLink());
    }
  };

  const prevStep = (e) => {
    e.preventDefault();
    setStep(step - 1);
    dispatch(onHideLink());
  };

  const validateJobTitle = (value) => {
    let valid = true;
    if (!value || value.trim().length === 0) {
      valid = false;
    }
    return valid;
  };

  const jobTitleChangeHandler = (e) => {
    setJobTitleError("");
    setError(null);
    setShowError(false);
    const { value } = e.target;
    if (!validateJobTitle(value)) {
      setJobTitleError("Please enter a valid Job Title");
    } else if (value.length < 4) {
      setJobTitleError("Should have minimum four characters");
    }
    setJobTitle(value);
  };

  const jobFamilyGroupChangeHandler = (data) => {
    setJobFamily("");
    setJobProfileWithId("");
    setManagementLevel("");
    let value = "";
    if (data) {
      value = data.value;
    }
    setJobFamilyGroup(data);
    setJobFamilyGroupId(value);
  };

  const jobFamilyChangeHandler = (data) => {
    setJobProfileWithId("");
    setManagementLevel("");
    let value = "";
    if (data) {
      value = data.value;
    }
    setJobFamily(data);
    setJobFamilyId(value);
  };

  const jobProfileChangeHandler = (data) => {
    setShowError(false);
    setManagementLevel("");
    setShowFormError(false);
    let result = data ? data.value : "";
    if (result !== "") {
      const array = result.split("");
      let finalValue = array[array.length - 1];
      if (finalValue === "N" || finalValue === "M") {
        finalValue = array[array.length - 2];
      }
      setManagementLevel(parseInt(finalValue));
    }
    setJobProfileWithId(data);
  };

  const jobProfileValueChangeHandler = (data) => {
    setShowError(false);
    setManagementLevel("");
    setShowFormError(false);
    if (data) {
      const res = jobProfilesInTheTeam.find(
        (value) => value.jobProfile === data.value
      );
      setJobFamilyGroup({
        value: res.jobFamilyGroup,
        label: res.jobFamilyGroup,
      });
      setJobFamily({
        value: res.jobFamily.toUpperCase(),
        label: res.jobFamily.toUpperCase(),
      });
      setManagementLevel(parseInt(res.managementLevel));
    }

    setJobProfileWithId(data);
  };

  const onLocationChangeHandler = (value) => {
    setLocationError(false);
    setLocation(value);
  };

  const maxSizeFile = (files) => {
    let valid = true;
    for (let i = 0; i < files.length; i++) {
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 1024000) {
          valid = false;
        }
      }
    }
    return valid;
  };

  const checkMimeType = (files) => {
    const types = [
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    let valid = true;
    for (let i = 0; i < files.length; i++) {
      if (types.every((type) => files[i].type !== type)) {
        valid = false;
      }
    }
    return valid;
  };

  const commentsChangeHandler = (event) => {
    setShowError(false);
    setTextAreaError("");
    setError(null);
    const { value } = event.target;

    if (!validateJobTitle(value)) {
      setTextAreaError("Please enter a valid reason for change");
    } else if (value.length < 10 || value.length > 250) {
      setTextAreaError("Should have 10 - 250 characters !!");
    }
    setComments(value);
  };

  const handleUploadFile = (event) => {
    event.preventDefault();
    setJdLink(null);
    setJdUploadError("");
    setShowError(false);
    setShowJdUploadError(false);
    setFileUploadError("");
    setError(null);
    const files = event.target.files;
    const file = files[0];

    const reader = new FileReader();
    reader.onload = async (e) => {
      const content = e.target.result;
      let doc = new Docxtemplater(new PizZip(content), {
        paragraphLoop: true,
        linebreaks: true,
      });
      let text = doc.getFullText();

      dispatch(onUpdatedJdText(text));
    };
    reader.readAsBinaryString(file);

    const fileArray = Array.from(files);
    let errorMessage = "";
    if (fileArray.length === 0) {
      errorMessage = "Please upload a attachment";
    } else if (!maxSizeFile(files)) {
      errorMessage = "File size should be less than 1 MB";
    } else if (!checkMimeType(files)) {
      errorMessage = "only .docx format is supported";
    } else if (fileArray.length !== 0 && !jdLink) {
      setJdUploadError("Please upload the selected file");
    } else if (fileArray.length === 0 && !jdLink) {
      setJdUploadError("Please choose a file to Upload");
    }
    setFileUploadError(errorMessage);
    setSelectedFile(event.target.files[0]);
    setSelectedFiles((prevState) => ({
      ...prevState,
      file,
      fileArray,
    }));
  };

  const onUploadJdLink = (link) => {
    if (link.length !== 0) {
      setJdLink(link);
    }
    setShowJdUploadError(false);
  };

  let managementLevelError = null;
  if ((managementLevel !== "" && managementLevel < 3) || managementLevel > 5) {
    managementLevelError = (
      <span className="error-message-form">
        Management Level should be within 3-5
      </span>
    );
  } else if (
    managementLevel !== "" &&
    managementLevel <= managementLevelOfLineManger
  ) {
    managementLevelError = (
      <span className="error-message-form">
        Same/Higher Management Level reporting is not allowed
      </span>
    );
  }

  const updateSelectedJobProfileHandler = (jobProfile) => {};

  let content = error;
  if (step === 1) {
    content = (
      <React.Fragment>
        <div className="form-flex" style={{ marginBottom: "40px" }}>
          <div className="form__group" style={{ marginBottom: "63px" }}>
            <label htmlFor="job-title" className="form__select__label">
              Job Title <span style={{ color: "#dd1d21" }}>*</span>
            </label>
            <input
              disabled={props.disabled}
              required
              maxLength="40"
              id="job-title"
              name="jobTitle"
              value={jobTitle}
              placeholder=""
              className={`form__input ${jobTitle === "" ? "" : "not-empty"} ${
                showError && jobTitle === "" ? "form__input__error" : ""
              }`}
              type="text"
              onChange={jobTitleChangeHandler}
            />
            {jobTitleError.length > 0 && (
              <span className="error-message-form">{jobTitleError}</span>
            )}
          </div>
          <div className="form__group"style={{ marginLeft: "100px" }} >
            <label className="form__select__label" htmlFor="comments">
              Reason for Action <span style={{ color: "#dd1d21" }}>*</span>
            </label>
            <div className="form-text-area">
              <textarea
                maxLength="13000"
                id="cancellationNotes"
                name="comment"
                value={comments}
                className={`${comments === "" ? "" : "not-empty"} ${
                  showError && comments === "" ? "form__input__error" : ""
                }`}
                onChange={commentsChangeHandler}
              ></textarea>

              {textAreaError.length > 0 && (
                <span className="error-message-form">{textAreaError}</span>
              )}
            </div>
          </div>
        </div>
        <div className="form-flex">
          <div className="form__group">
            <div className="form__group" style={{ marginBottom: "25px" }}>
              <label className="form__select__label">
                Select a profile that already exists in your team
              </label>
              <Select
                disabled={props.disabled}
                onFocus={() => setIsFocused(true)}
                className="medium"
                value={jobProfileWithId}
                options={jobProfileInTeamOptions}
                onChange={(value) => jobProfileValueChangeHandler(value)}
                showError={showError}
              />{" "}
            </div>
            <div
              className="form__group util-mar"
              style={{ marginBottom: "25px" }}
            >
              <label className="form__select__label">Management Level </label>
              <input
                maxLength="40"
                id="job-title"
                name="jobTitle"
                value={managementLevel}
                placeholder=""
                className={`form__input ${
                  managementLevel === "" ? "" : "not-empty"
                }`}
                type="number"
                disabled
              />
              {managementLevelError}
            </div>
            <div className="form__group">
              <label className="form__select__label">
                Country <span style={{ color: "#dd1d21" }}>*</span>
              </label>
              <Select
                value={location}
                options={locationOptions}
                onChange={(value) => onLocationChangeHandler(value)}
                showError={showError}
              />
              {locationError && (
                <span className="error-message-form">
                  Please select a country
                </span>
              )}
            </div>
          </div>
          <div className="form__group__jobProfile"
          style={{ marginLeft: "90px" }}>
            <div className="background-or">
              <p className="optional-text">OR Select from the Job Catalogue</p>
            </div>
            <div className="form__group">
              <label className="form__select__label util-padding-label">
                Job Family Group <span style={{ color: "#dd1d21" }}>*</span>
              </label>
              <div onClick={() => setIsFocused(false)} className="form-element">
                <Select
                  disabled={isFocused || props.disabled}
                  value={jobFamilyGroup}
                  options={jobFamilyGroupOptions}
                  onChange={(value) => jobFamilyGroupChangeHandler(value)}
                  showError={showError}
                />
              </div>
            </div>

            <div className="form__group">
              <label className="form__select__label">
                Job Family <span style={{ color: "#dd1d21" }}>*</span>
              </label>
              <Select
                disabled={isFocused || props.disabled}
                value={jobFamily}
                options={jobFamilyOptions}
                onChange={(value) => jobFamilyChangeHandler(value)}
                showError={showError}
              />
            </div>

            <div className="form__group">
              <label className="form__select__label">
                Job Profile with Job ID{" "}
                <span style={{ color: "#dd1d21" }}>*</span>
              </label>
              <Select
                disabled={isFocused || props.disabled}
                value={jobProfileWithId}
                options={jobProfileWithIdOptions}
                onChange={(value) => jobProfileChangeHandler(value)}
                showError={showError}
              />
              {showFormError && (
                <span className="error-message-form">
                  Please select a valid Job Profile
                </span>
              )}
            </div>
          </div>
        </div>

        {props.isPositionEdited || props.isNewPosition ? (
          <div className="form__group" style={{ margin: "25px 10px" }}>
            <label className="form__select__label">
              Job Description <span style={{ color: "#dd1d21" }}>*</span> (
                .docx file with sensitivity label <span className ="form__button__notification">Internal</span> or <span className ="form__button__notification">Confidential (Anyone/Unencrypted)</span>, in English only){"  "}
              <ToolTip message={jdTextLabel}>
                <span className="icon-ball">i</span>
              </ToolTip>
            </label>
            <div className="flex-change">
              <Upload
                disabled={
                  managementLevelOfLineManger === managementLevel ||
                  managementLevel <= 2 ||
                  managementLevel >= 6 ||
                  jobTitle === "" ||
                  jobTitle.trim().length === 0
                }
                onChange={handleUploadFile}
                name="file"
                filesArray={selectedFiles.fileArray}
                file={selectedFile}
                error={fileUploadError}
                onUploadJD={(jdLink) => onUploadJdLink(jdLink)}
                showError={showError}
                managementLevel={managementLevel}
                jobTitle={jobTitle}
                organisationLayer={maxNoOfLayersOfTeam}
                skillPoolGroup={jobFamilyGroup}
                jobProfile={jobProfileWithId}
              />
              {showJdUploadError && (
                <span className="error-message-form">
                  {!jdUploadError
                    ? "Please choose a file to Upload"
                    : jdUploadError}
                </span>
              )}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  } else if (step === 2) {
    content = (
      <DescriptionPage
        isManager={props.isManager}
        isNewPosition={props.isNewPosition}
        isEdited={props.isPositionEdited}
        isDuplicated={props.isDuplicatedPosition}
        positionId={props.positionId}
        coeOfPosition={props.coeOfPosition}
        prevStep={prevStep}
        nextStep={nextStep}
        positionTitle={jobTitle}
        jobFamilyGroup={jobFamilyGroup}
        jobFamily={jobFamily}
        jobProfile={jobProfileWithId}
        managementLevel={Number(managementLevel)}
        location={location}
        jobDescription={selectedFile}
        jdLink={jdLink && jdLink.length !== 0 ? jdLink[0] : null}
        reasonForChange={comments}
        isUnfilled={props.isUnfilled}
        updateSelectedJobProfile={updateSelectedJobProfileHandler}
        aiManagementLevel={aiManagementLevel}
        confidence={confidence}
        jdParsedTextAi={jdParsedTextAi}
        aiResponse={aiResponse}
      />
    );
  } else if (step === 3 || step === 0) {
    history.replace("/home");
  }

  let nextButtonTitle = "NEXT";
  if (step === 2) {
    nextButtonTitle = "CONFIRM";
  } else if (step === 3) {
    nextButtonTitle = "GO TO HOME PAGE";
  }

  return (
    <div className="form-wrapper">
      <LoadingOverlay
        active={isLoading}
        spinner
        text="EVA is now processing your information"
      >
        <h3 className="form-title">
          {step === 1 ? "Please provide the details" : "Job Family Overview"}
        </h3>
        <div className="form-new">
          {step === 1 ? (
            <span className="error-message-form util-margin-left">
              <span style={{ color: "#dd1d21" }}>*</span> Mandatory Fields
            </span>
          ) : null}
          {showError && step === 1 ? (
            <span className="error-message-form util-margin-top">
              Please provide the required details
            </span>
          ) : null}
          <form className="form-main">
            {content}
            {step === 1 ? (
              <div className="form__group__submit">
                <Button
                  disabled={step === 0 || step === 3}
                  onClick={prevStep}
                  style={{ padding: "15px 20px" }}
                >
                  BACK
                </Button>
                <Button
                  disabled={step === 4}
                  onClick={nextStep}
                  style={{ padding: "15px 20px" }}
                >
                  {nextButtonTitle}
                </Button>
              </div>
            ) : null}
          </form>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default FormContainer;
