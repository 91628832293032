import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";

import { selectAuth, resetOsh } from "../../app/features/authSlice";
import {
  fetchDetails,
  resetCostAndChanges,
} from "../../app/features/lineManagerSlice";
import Spinner from "../../components/spinner/Spinner";

import Instructions from "../../containers/instructions/Instructions";
import WelcomePageLinks from "../../containers/welcome-page-links/WelcomePageLinks";
import Error from "../not-authorized-error/Error";
import warning from "../../assets/warningIcon.png";

const Welcome = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { positionId, error } = useSelector(selectAuth);

  useEffect(() => {
    if (positionId) {
      const customButtonClasses = Swal.mixin({
        customClass: {
          confirmButton: "change-button warning",
          container:'swal-wide'
        },
        buttonsStyling: false,
      });
      // customButtonClasses.fire({
      //   // html: `<p style="margin:0;font-size:16px;text-align:center;padding:5px 0 0 10px;font-weight:700;">This position can not be removed as that would impact the reporting lines of positions underneath. Reach out to HR Advice to discuss.</p>`,
      //   imageUrl: warning,
      //   imageWidth: 75,
      //   imageHeight: 75,
      //   imageAlt: "warning",
      //   html: `<p style="margin:0;font-size:15px;text-align:justify;padding:0 0 0 5px;font-weight:700;">EVA reflects the organisational structure as of the 20th of March 2023. Due to current Workday freeze the data in EVA will not be refreshed until the 29th of March 2023.</p>
      //          <p style="margin:0;font-size:15px;text-align:justify;padding:15px 0 0 5px;font-weight:700;">If there are changes in your team not reflected in the current view of the structure, please hold off and model the changes after the 29th of March to get an accurate assessment.</p>`,
      //          //<p style="margin:0;font-size:15px;text-align:left;padding:15px 0 0 5px;font-weight:700;">Thank you for your understanding.</p>
      //   confirmButtonText: "OK",
      //   cancelButtonText: "Cancel",
      //   width: "550px",
      // });
      dispatch(resetOsh());
      dispatch(resetCostAndChanges());
      dispatch(fetchDetails(positionId));
      setIsLoading(false);
    } else if (error) {
      setIsLoading(false);
    }
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  }, [dispatch, positionId, error]);

  let content = isLoading && <Spinner />;
  if (positionId) {
    content = (
      <div className="container">
        <Instructions />
        <WelcomePageLinks />
      </div>
    );
  } else if (error) {
    content = <Error />;
  }

  return content;
};

export default Welcome;
